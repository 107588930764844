import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
//material-ui
import { AppBar, Toolbar, Typography, IconButton, Box } from "@mui/material";

import MenuIcon from "@mui/icons-material/Menu";
import PageButtons from "./PageButtons";
import Config from "../config";

interface TopBarProps {
	setDrawerOpen: (open: boolean) => void;
	children: JSX.Element;
}

const pageTitleMap = (pathname: string) => {
	switch (pathname) {
		case "/post":
			return "Post Counts";
		case "/done":
			return "Posted Counts";
		case "/settings":
			return "Settings";
		default:
			return "Availability";
	}
};

const TopBar = ({ setDrawerOpen, children }: TopBarProps) => {
	const [pageTitle, setPageTitle] = useState("Availability");
	const { pathname } = useLocation();

	useEffect(() => {
		setPageTitle(pageTitleMap(pathname));
	}, [pathname]);

	return (
		<AppBar id="appHeader" position="sticky">
			{Config.environment !== "production" ? (
				<Box
					sx={{
						fontSize: ".6em",
						fontweight: "bold",
						position: "absolute",
						textAlign: "center",
						width: "100%",
						backgroundColor: "warning.main",
						color: "warning.contrastText",
					}}
				>
					&nbsp;{Config.branch} - {Config.buildVersion}&nbsp;
				</Box>
			) : null}
			<Toolbar>
				<IconButton color="inherit" aria-label="open drawer" onClick={() => setDrawerOpen(true)} edge="start">
					<MenuIcon fontSize="large" />
				</IconButton>

				<Typography variant="h6" noWrap sx={{ paddingLeft: 2 }}>
					{pageTitle}
				</Typography>
				<PageButtons />
			</Toolbar>
			{children}
		</AppBar>
	);
};

export default TopBar;
