const Config = {
	dexieVersion: 1,
	decimalPrecision: 5,
	buildVersion: import.meta.env["VITE_BUILD_VERSION"]!,
	releaseVersion: import.meta.env["VITE_RELEASE_VERSION"]!,
	environment: import.meta.env["VITE_BUILD_ENV"]!,
	branch: import.meta.env["VITE_BUILD_BRANCH"]!,
	sentryDSN: import.meta.env["VITE_SENTRY_DSN"]!,
	endpoint: import.meta.env["VITE_GRAPHQL_ENDPOINT"]!,
	authUrl: import.meta.env["VITE_AUTH_URL"]!,
	sessionManagerUrl: import.meta.env["VITE_SESSION_MANAGER_URI"]!,
	sfApiUrl: import.meta.env["VITE_SF_API_URL"]!,
	appName: import.meta.env["VITE_APPLICATION_NAME"]!,
	clientId: import.meta.env["VITE_CLIENT_ID"]!,
	tenantId: import.meta.env["VITE_TENANT_ID"]!,
	usePKCE: import.meta.env["VITE_USE_PKCE"] === "true",
	tokenDuration: parseInt(import.meta.env["VITE_TOKEN_DURATION_SECONDS"]!) || 3600,
};

export default Config;
