import { Link } from "react-router-dom";

//material-ui
import { styled } from "@mui/system";

import { Drawer, DrawerProps, List, Divider, ListItem, ListItemIcon, ListItemText, IconButton } from "@mui/material";

import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import HomeIcon from "@mui/icons-material/Home";
import AspectRatioIcon from "@mui/icons-material/AspectRatio";
import SettingsIcon from "@mui/icons-material/Settings";

export const drawerWidth = "100%";
export const drawerWidthMax = 360;

const MainNavDrawer = styled(Drawer)<DrawerProps>(({ theme }) => ({
	display: "flex",
	width: drawerWidth,
	maxWidth: drawerWidthMax,
	flexShrink: 0,
	"& .MuiDrawer-paper": {
		width: drawerWidth,
		maxWidth: drawerWidthMax,
	},
	"& .MUIDrawer-header": {
		display: "flex",
		alignItems: "center",
		padding: theme.spacing(0, 1),
		// necessary for content to be below app bar
		//...theme.mixins.toolbar,
		justifyContent: "flex-end",
	},
	"& a": {
		textDecoration: "none",
		color: "inherit",
	},
}));

interface NavDrawerProps {
	drawerOpen: boolean;
	setDrawerOpen: (open: boolean) => void;
}

function NavDrawer({ drawerOpen, setDrawerOpen }: NavDrawerProps) {
	//navigation menu
	var navMenu = [
		{
			name: "Home",
			path: "/",
			icon: <HomeIcon />,
		},
	];

	const toggleFullScreen = () => {
		var doc = window.document;
		var docEl = doc.documentElement;

		var requestFullScreen = docEl.requestFullscreen;
		var cancelFullScreen = doc.exitFullscreen;

		if (!doc.fullscreenElement) {
			requestFullScreen.call(docEl);
		} else {
			cancelFullScreen.call(doc);
		}
	};

	return (
		<MainNavDrawer variant="persistent" anchor="left" open={drawerOpen}>
			<div>
				<IconButton onClick={() => setDrawerOpen(false)} size="large">
					{drawerOpen ? <ChevronLeftIcon /> : <ChevronRightIcon />}
				</IconButton>
			</div>

			<Divider />

			<List>
				{navMenu.map(function (item, index) {
					return (
						<Link to={item.path} key={index} onClick={() => setDrawerOpen(false)}>
							<ListItem key={index}>
								<ListItemIcon>{item.icon}</ListItemIcon>
								<ListItemText primary={item.name} />
							</ListItem>
						</Link>
					);
				})}
			</List>
			<Divider />
			<List>
				<Link to={"/settings"} onClick={() => setDrawerOpen(false)}>
					<ListItem>
						<ListItemIcon>
							<SettingsIcon />
						</ListItemIcon>
						<ListItemText primary="Settings" />
					</ListItem>
				</Link>
			</List>
			<List>
				<ListItem key="fullscreeen" onClick={toggleFullScreen}>
					<ListItemIcon>
						<AspectRatioIcon />
					</ListItemIcon>
					<ListItemText primary="Full Screen" />
				</ListItem>
			</List>
		</MainNavDrawer>
	);
}

export default NavDrawer;
