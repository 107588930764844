import { useContext } from "react";
import AppThemeProvider from "./providers/AppThemeProvider";
import { BrowserRouter } from "react-router-dom";
import Layout from "./layout/Layout";
import { SnackbarProvider } from "notistack";
import TranslationProvider from "./providers/TranslationProvider";
import { AuthContext } from "./auth";
import * as Sentry from "@sentry/react";
import Config from "./config";

//providers/hooks
import { ConfirmProvider } from "material-ui-confirm";
import GraphQLProvider from "./providers/GraphqlProvider";

const App = () => {
	const authContext = useContext(AuthContext);

	if (!Sentry.getCurrentScope().getClient()) {
		Sentry.init({
			dsn: Config.sentryDSN,
			release: Config.buildVersion,
			environment: Config.environment,
			tracesSampleRate: Config.environment === "production" ? 0.1 : 1.0,
			replaysSessionSampleRate: Config.environment === "production" ? 0.01 : 0.1,
			replaysOnErrorSampleRate: 0.1,
			sendDefaultPii: true,
			transport: Sentry.makeBrowserOfflineTransport(Sentry.makeFetchTransport),
			integrations: [
				new Sentry.BrowserTracing(),
				Sentry.replayIntegration({
					maskAllText: false,
					blockAllMedia: false,
				}),
			],
		});
	}

	Sentry.setUser({
		email: authContext.currentUser?.email || "notAvailable",
		username: authContext.currentUser?.firstName + " " + authContext.currentUser?.lastName,
	});

	return (
		<GraphQLProvider>
			<TranslationProvider>
				<AppThemeProvider>
					<SnackbarProvider
						dense
						anchorOrigin={{
							vertical: "bottom",
							horizontal: "center",
						}}
					>
						<ConfirmProvider>
							<BrowserRouter>
								<Layout />
							</BrowserRouter>
						</ConfirmProvider>
					</SnackbarProvider>
				</AppThemeProvider>
			</TranslationProvider>
		</GraphQLProvider>
	);
};
export default App;
