import { useContext } from "react";

//theme
import { ThemeContext } from "../providers/AppThemeProvider";

//material-ui
import { Button, Grid, Typography } from "@mui/material";
import SwitchUI from "@mui/material/Switch";
import { useConfirm } from "material-ui-confirm";

//providers/hooks
import { AuthContext } from "../auth/AuthProvider";
import { TranslationContext } from "../providers/TranslationProvider";
import { useSnacks } from "../providers/SnackProvider";
import db from "../db";

import Config from "../config";
import SelectNative from "../components/SelectNative";
import SpacedGridItem from "../components/SpacedGridItem";

const Settings = () => {
	const { themeType, setThemeType, themeSize, setThemeSize } = useContext(ThemeContext);
	const { getTranslation } = useContext(TranslationContext);
	const authContext = useContext(AuthContext);
	const confirm = useConfirm();
	const [openSnack] = useSnacks();

	const logout = async () => {
		authContext.redirectToLogout();
	};

	const handleThemeChange = (event: any) => {
		const { checked } = event.target;
		if (checked) {
			setThemeType("dark");
		} else {
			setThemeType("light");
		}
	};

	const handleThemeSizeChange = (size: string) => {
		setThemeSize(size);
	};

	const handleDeleteDb = async () => {
		confirm({
			title: "Clear all data including saved counts?",
			cancellationButtonProps: { variant: "outlined" },
			confirmationButtonProps: { variant: "contained", color: "primary" },
		})
			.then(() => {
				db.delete()
					.then(() => {
						openSnack("Database deleted", "success");
						window.location.reload();
					})
					.catch((err) => {
						console.error(err);
						openSnack("Database not deleted", "error");
					})
					.finally(() => {
						// Do what should be done next...
					});
			})
			.catch();
	};

	return (
		<Grid container alignItems="stretch" direction="row">
			{authContext.currentUser ? (
				<>
					<SpacedGridItem maxCols={3} smMargin>
						<Typography variant="h6">{getTranslation("settings-user-info")}</Typography>
						<dl>
							<dd>
								<b>Name:</b> {authContext.currentUser.firstName} {authContext.currentUser.lastName}
							</dd>
							<dd>
								<b>Email:</b> {authContext.currentUser.email}
							</dd>
							<dd>
								<b>Company:</b> {authContext.company?.name}
							</dd>
						</dl>
					</SpacedGridItem>
					<SpacedGridItem maxCols={3} smMargin>
						<Typography variant="h6">{getTranslation("settings-api-endpoint")}</Typography>

						<dl>
							<dd>{Config.endpoint}</dd>
						</dl>
					</SpacedGridItem>
				</>
			) : null}
			<SpacedGridItem maxCols={3} smMargin>
				<Typography variant="h6">{getTranslation("settings-dark-theme")}</Typography>
				<SwitchUI checked={themeType === "dark"} onChange={handleThemeChange} />
			</SpacedGridItem>
			<SpacedGridItem maxCols={3} smMargin>
				<Typography variant="h6">{getTranslation("settings-text-size")}</Typography>
				<SelectNative
					sx={{ padding: 1 }}
					variant="outlined"
					label=""
					options={[
						{ value: 10, label: "1" },
						{ value: 12, label: "2" },
						{ value: 14, label: "3 (Default)" },
						{ value: 16, label: "4" },
						{ value: 18, label: "5" },
						{ value: 20, label: "6" },
					]}
					onChange={(e: any) => handleThemeSizeChange(e.target.value)}
					value={themeSize}
				/>
			</SpacedGridItem>
			<SpacedGridItem maxCols={3} smMargin>
				<Typography variant="h6">{getTranslation("settings-version")}</Typography>
				<dl>
					<dd>Release: {Config.releaseVersion}</dd>
					<dd>Build: {Config.buildVersion}</dd>
				</dl>
			</SpacedGridItem>
			<SpacedGridItem maxCols={3} smMargin>
				<Button color="secondary" variant="contained" fullWidth={true} onClick={() => logout()}>
					Sign Out
				</Button>
			</SpacedGridItem>
			<SpacedGridItem maxCols={1} smMargin>
				<p style={{ fontSize: "smaller", opacity: 0.5 }}>{navigator.userAgent}</p>
			</SpacedGridItem>
			<SpacedGridItem smMargin>
				<Button variant="outlined" onClick={() => handleDeleteDb()} color="inherit" fullWidth={true}>
					{getTranslation("action-delete-app-database")}
				</Button>
			</SpacedGridItem>
		</Grid>
	);
};
export default Settings;
