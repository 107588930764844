import db from "../db";
//days to keep processed counts locally
export const daysToKeep = 7;

export const pruneCounts = () => {};
let deleteFrom = new Date().getTime() - daysToKeep * 24 * 60 * 60 * 1000;
db.itemCounts
	.filter((c) => new Date(c.itemCount.recordedAt).getTime() < deleteFrom)
	.filter((c) => c.status === "posted")
	.delete()
	.catch(() => {
		console.log("Error deleting old posted counts");
		return;
	});
