import { GraphQLClient, RequestOptions } from 'graphql-request';
import gql from 'graphql-tag';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
type GraphQLClientRequestHeaders = RequestOptions['requestHeaders'];
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  DateTime: { input: any; output: any; }
  Decimal: { input: any; output: any; }
  Long: { input: any; output: any; }
  ObjectId: { input: any; output: any; }
  UUID: { input: any; output: any; }
};

export type ActivateCompanyInput = {
  companyId: Scalars['String']['input'];
  name: Scalars['String']['input'];
  tenantId: Scalars['String']['input'];
};

export type ActivateItemInput = {
  itemId: Scalars['String']['input'];
  sku: Scalars['String']['input'];
  tenantId: Scalars['String']['input'];
};

export type ActivateTenantInput = {
  entityId: Scalars['String']['input'];
};

export type AddBillingAddressInput = {
  address: AddressDataInput;
  companyId: Scalars['String']['input'];
  tenantId: Scalars['String']['input'];
};

export type AddCategoryInput = {
  category: CategoryDataInput;
  tenantId: Scalars['String']['input'];
};

export type AddCompanyInput = {
  companyId: Scalars['String']['input'];
  currency: Scalars['String']['input'];
  description: Scalars['String']['input'];
  erpId: Scalars['String']['input'];
  erpPriceGroupId: Scalars['String']['input'];
  name: Scalars['String']['input'];
  tenantId: Scalars['String']['input'];
};

export type AddContactInput = {
  companyId: Scalars['String']['input'];
  contact: ContactDataInput;
  tenantId: Scalars['String']['input'];
};

export type AddCountInput = {
  comment: Scalars['String']['input'];
  countRowId: Scalars['String']['input'];
  grade: Scalars['Int']['input'];
  itemId: Scalars['String']['input'];
  locationId: Scalars['String']['input'];
  quantity: Scalars['Int']['input'];
  recordedAt: Scalars['DateTime']['input'];
  recordedBy: Scalars['String']['input'];
  sessionId: Scalars['String']['input'];
  tenantId: Scalars['String']['input'];
};

export type AddItemAvailabilityInput = {
  comment: Scalars['String']['input'];
  itemId: Scalars['String']['input'];
  locationCode: Scalars['String']['input'];
  locationId: Scalars['String']['input'];
  quantity: Scalars['Int']['input'];
  recordedAt: Scalars['DateTime']['input'];
  recordedBy: Scalars['String']['input'];
  tenantId: Scalars['String']['input'];
};

export type AddItemInput = {
  categories: Array<CategoryDataInput>;
  description: Scalars['String']['input'];
  erpId?: Scalars['String']['input'];
  itemGroup: Scalars['String']['input'];
  itemId: Scalars['String']['input'];
  name: Scalars['String']['input'];
  programs?: InputMaybe<Array<Scalars['String']['input']>>;
  sellPrice?: InputMaybe<Scalars['Decimal']['input']>;
  sku: Scalars['String']['input'];
  tenantId: Scalars['String']['input'];
  upc: Scalars['String']['input'];
};

export type AddItemToCartInput = {
  cartId: Scalars['String']['input'];
  currency: Scalars['String']['input'];
  itemGroup?: InputMaybe<Scalars['String']['input']>;
  itemId: Scalars['String']['input'];
  name: Scalars['String']['input'];
  price: Scalars['Decimal']['input'];
  quantity: Scalars['Int']['input'];
  sku: Scalars['String']['input'];
  tenantId: Scalars['String']['input'];
  upc: Scalars['String']['input'];
};

export type AddLineItemsInput = {
  items: Array<LineItemDataInput>;
  orderId: Scalars['String']['input'];
  tenantId: Scalars['String']['input'];
};

export type AddLocationInput = {
  companyId: Scalars['String']['input'];
  location: LocationDataInput;
  tenantId: Scalars['String']['input'];
};

export type AddNoteInput = {
  companyId: Scalars['String']['input'];
  noteId: Scalars['String']['input'];
  noteText: Scalars['String']['input'];
  submittedBy: Scalars['String']['input'];
  tenantId: Scalars['String']['input'];
};

export type AddShipFromLocationInput = {
  companyId: Scalars['String']['input'];
  location: ShipFromLocationDataInput;
  tenantId: Scalars['String']['input'];
};

export type AddSizesInput = {
  sizes: SizesDataInput;
  tenantId: Scalars['String']['input'];
};

export type AddTenantInput = {
  entityId: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type AddUnitInput = {
  code: Scalars['String']['input'];
  description: Scalars['String']['input'];
  tenantId: Scalars['String']['input'];
};

export type AddressData = {
  __typename?: 'AddressData';
  addressLine1: Scalars['String']['output'];
  addressLine2: Scalars['String']['output'];
  city: Scalars['String']['output'];
  country: Scalars['String']['output'];
  id: Scalars['UUID']['output'];
  stateProvince: Scalars['String']['output'];
  toName: Scalars['String']['output'];
  zipcode: Scalars['String']['output'];
};

export type AddressDataFilterInput = {
  addressLine1?: InputMaybe<StringOperationFilterInput>;
  addressLine2?: InputMaybe<StringOperationFilterInput>;
  and?: InputMaybe<Array<AddressDataFilterInput>>;
  city?: InputMaybe<StringOperationFilterInput>;
  country?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  or?: InputMaybe<Array<AddressDataFilterInput>>;
  stateProvince?: InputMaybe<StringOperationFilterInput>;
  toName?: InputMaybe<StringOperationFilterInput>;
  zipcode?: InputMaybe<StringOperationFilterInput>;
};

export type AddressDataInput = {
  addressLine1: Scalars['String']['input'];
  addressLine2: Scalars['String']['input'];
  city: Scalars['String']['input'];
  country: Scalars['String']['input'];
  id: Scalars['UUID']['input'];
  stateProvince: Scalars['String']['input'];
  toName: Scalars['String']['input'];
  zipcode: Scalars['String']['input'];
};

export type AddressDataSortInput = {
  addressLine1?: InputMaybe<SortEnumType>;
  addressLine2?: InputMaybe<SortEnumType>;
  city?: InputMaybe<SortEnumType>;
  country?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  stateProvince?: InputMaybe<SortEnumType>;
  toName?: InputMaybe<SortEnumType>;
  zipcode?: InputMaybe<SortEnumType>;
};

export enum ApplyPolicy {
  AfterResolver = 'AFTER_RESOLVER',
  BeforeResolver = 'BEFORE_RESOLVER',
  Validation = 'VALIDATION'
}

export type AvailabilityAdjustmentData = {
  __typename?: 'AvailabilityAdjustmentData';
  comment: Scalars['String']['output'];
  direction: AvailabilityAdjustmentDirection;
  itemId: Scalars['String']['output'];
  itemName: Scalars['String']['output'];
  quantity: Scalars['Int']['output'];
  reason: AvailabilityAdjustmentReason;
  recordedAt: Scalars['DateTime']['output'];
  recordedBy: Scalars['String']['output'];
  sku: Scalars['String']['output'];
  upc: Scalars['String']['output'];
};

export enum AvailabilityAdjustmentDirection {
  Decrease = 'DECREASE',
  Increase = 'INCREASE'
}

export enum AvailabilityAdjustmentReason {
  Ordered = 'ORDERED',
  OrderCancelled = 'ORDER_CANCELLED'
}

export type AvailabilityCountData = {
  __typename?: 'AvailabilityCountData';
  comment: Scalars['String']['output'];
  countRowId: Scalars['String']['output'];
  grade: Scalars['Int']['output'];
  itemId: Scalars['String']['output'];
  itemName: Scalars['String']['output'];
  locationId: Scalars['String']['output'];
  quantity: Scalars['Int']['output'];
  recordedAt: Scalars['DateTime']['output'];
  recordedBy: Scalars['String']['output'];
  sku: Scalars['String']['output'];
  upc: Scalars['String']['output'];
};

export type AvailabilitySessionDocument = {
  __typename?: 'AvailabilitySessionDocument';
  id: Scalars['String']['output'];
  recordedAt: Scalars['DateTime']['output'];
  sessionId: Scalars['String']['output'];
  status: AvailabilitySessionStatus;
  tenantId: Scalars['String']['output'];
  totalNumberOfAdjustments: Scalars['Int']['output'];
  totalNumberOfCounts: Scalars['Int']['output'];
};

export type AvailabilitySessionDocumentFilterInput = {
  and?: InputMaybe<Array<AvailabilitySessionDocumentFilterInput>>;
  id?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<AvailabilitySessionDocumentFilterInput>>;
  recordedAt?: InputMaybe<DateTimeOperationFilterInput>;
  sessionId?: InputMaybe<StringOperationFilterInput>;
  status?: InputMaybe<AvailabilitySessionStatusOperationFilterInput>;
  tenantId?: InputMaybe<StringOperationFilterInput>;
  totalNumberOfAdjustments?: InputMaybe<IntOperationFilterInput>;
  totalNumberOfCounts?: InputMaybe<IntOperationFilterInput>;
};

export type AvailabilitySessionDocumentSortInput = {
  id?: InputMaybe<SortEnumType>;
  recordedAt?: InputMaybe<SortEnumType>;
  sessionId?: InputMaybe<SortEnumType>;
  status?: InputMaybe<SortEnumType>;
  tenantId?: InputMaybe<SortEnumType>;
  totalNumberOfAdjustments?: InputMaybe<SortEnumType>;
  totalNumberOfCounts?: InputMaybe<SortEnumType>;
};

export type AvailabilitySessionLogData = {
  __typename?: 'AvailabilitySessionLogData';
  comment: Scalars['String']['output'];
  recordedAt: Scalars['DateTime']['output'];
  recordedBy: Scalars['String']['output'];
};

export type AvailabilitySessionPayload = {
  __typename?: 'AvailabilitySessionPayload';
  errors?: Maybe<Array<ValidationError>>;
  session?: Maybe<AvailabilitySessionStateData>;
};

export type AvailabilitySessionStateData = {
  __typename?: 'AvailabilitySessionStateData';
  adjustments: Array<AvailabilityAdjustmentData>;
  counts: Array<AvailabilityCountData>;
  logs: Array<AvailabilitySessionLogData>;
  sessionId: Scalars['String']['output'];
  status: AvailabilitySessionStatus;
  tenantId: Scalars['String']['output'];
};

export enum AvailabilitySessionStatus {
  Closed = 'CLOSED',
  Live = 'LIVE',
  Staged = 'STAGED'
}

export type AvailabilitySessionStatusOperationFilterInput = {
  eq?: InputMaybe<AvailabilitySessionStatus>;
  in?: InputMaybe<Array<AvailabilitySessionStatus>>;
  neq?: InputMaybe<AvailabilitySessionStatus>;
  nin?: InputMaybe<Array<AvailabilitySessionStatus>>;
};

/** A connection to a list of items. */
export type AvailabilitySessionsConnection = {
  __typename?: 'AvailabilitySessionsConnection';
  /** A list of edges. */
  edges?: Maybe<Array<AvailabilitySessionsEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<AvailabilitySessionDocument>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type AvailabilitySessionsEdge = {
  __typename?: 'AvailabilitySessionsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: AvailabilitySessionDocument;
};

export type BooleanOperationFilterInput = {
  eq?: InputMaybe<Scalars['Boolean']['input']>;
  neq?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CarrierData = {
  __typename?: 'CarrierData';
  description: Scalars['String']['output'];
};

export type CartAddressData = {
  __typename?: 'CartAddressData';
  addressLine1: Scalars['String']['output'];
  addressLine2: Scalars['String']['output'];
  city: Scalars['String']['output'];
  country: Scalars['String']['output'];
  id: Scalars['String']['output'];
  stateProvince: Scalars['String']['output'];
  toName: Scalars['String']['output'];
  zipcode: Scalars['String']['output'];
};

/** A connection to a list of items. */
export type CategoriesConnection = {
  __typename?: 'CategoriesConnection';
  /** A list of edges. */
  edges?: Maybe<Array<CategoriesEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<CategoryCollection>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type CategoriesEdge = {
  __typename?: 'CategoriesEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: CategoryCollection;
};

export type CategoryCollection = {
  __typename?: 'CategoryCollection';
  category: CategoryData;
  id: Scalars['String']['output'];
  tenantId: Scalars['String']['output'];
};

export type CategoryCollectionFilterInput = {
  and?: InputMaybe<Array<CategoryCollectionFilterInput>>;
  category?: InputMaybe<CategoryDataFilterInput>;
  id?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<CategoryCollectionFilterInput>>;
  tenantId?: InputMaybe<StringOperationFilterInput>;
};

export type CategoryCollectionSortInput = {
  category?: InputMaybe<CategoryDataSortInput>;
  id?: InputMaybe<SortEnumType>;
  tenantId?: InputMaybe<SortEnumType>;
};

export type CategoryData = {
  __typename?: 'CategoryData';
  code: Scalars['String']['output'];
  name: Scalars['String']['output'];
  size: SizesData;
};

export type CategoryDataFilterInput = {
  and?: InputMaybe<Array<CategoryDataFilterInput>>;
  code?: InputMaybe<StringOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<CategoryDataFilterInput>>;
  size?: InputMaybe<SizesDataFilterInput>;
};

export type CategoryDataInput = {
  code: Scalars['String']['input'];
  name: Scalars['String']['input'];
  size: SizesDataInput;
};

export type CategoryDataSortInput = {
  code?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  size?: InputMaybe<SizesDataSortInput>;
};

export type CategoryPayload = {
  __typename?: 'CategoryPayload';
  data?: Maybe<CategoryPayloadData>;
  errors?: Maybe<Array<ValidationError>>;
};

export type CategoryPayloadData = {
  __typename?: 'CategoryPayloadData';
  category: CategoryData;
  id: Scalars['String']['output'];
  tenantId: Scalars['String']['output'];
};

export type CleanCompaniesInput = {
  erpIds: Array<Scalars['String']['input']>;
  tenantId: Scalars['String']['input'];
};

export type CleanItemsInput = {
  erpIds: Array<Scalars['String']['input']>;
  tenantId: Scalars['String']['input'];
};

export type CloseSessionInput = {
  comment: Scalars['String']['input'];
  recordedAt: Scalars['DateTime']['input'];
  recordedBy: Scalars['String']['input'];
  sessionId: Scalars['String']['input'];
  tenantId: Scalars['String']['input'];
};

export type CommandBarDocument = {
  __typename?: 'CommandBarDocument';
  aggregateId: Scalars['String']['output'];
  aggregateType: Scalars['String']['output'];
  id: Scalars['String']['output'];
  optionName: Scalars['String']['output'];
  optionValue: Scalars['String']['output'];
  tenantId: Scalars['String']['output'];
};

export type CommandBarDocumentFilterInput = {
  aggregateId?: InputMaybe<StringOperationFilterInput>;
  aggregateType?: InputMaybe<StringOperationFilterInput>;
  and?: InputMaybe<Array<CommandBarDocumentFilterInput>>;
  id?: InputMaybe<StringOperationFilterInput>;
  optionName?: InputMaybe<StringOperationFilterInput>;
  optionValue?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<CommandBarDocumentFilterInput>>;
  tenantId?: InputMaybe<StringOperationFilterInput>;
};

export type CommandBarDocumentSortInput = {
  aggregateId?: InputMaybe<SortEnumType>;
  aggregateType?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  optionName?: InputMaybe<SortEnumType>;
  optionValue?: InputMaybe<SortEnumType>;
  position?: InputMaybe<SortEnumType>;
  streamPosition?: InputMaybe<SortEnumType>;
  tenantId?: InputMaybe<SortEnumType>;
};

/** A connection to a list of items. */
export type CommandBarOptionsConnection = {
  __typename?: 'CommandBarOptionsConnection';
  /** A list of edges. */
  edges?: Maybe<Array<CommandBarOptionsEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<CommandBarDocument>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type CommandBarOptionsEdge = {
  __typename?: 'CommandBarOptionsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: CommandBarDocument;
};

/** A connection to a list of items. */
export type CompaniesConnection = {
  __typename?: 'CompaniesConnection';
  /** A list of edges. */
  edges?: Maybe<Array<CompaniesEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<CompaniesSummaryDocument>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type CompaniesEdge = {
  __typename?: 'CompaniesEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: CompaniesSummaryDocument;
};

export type CompaniesSummaryDocument = {
  __typename?: 'CompaniesSummaryDocument';
  companyId: Scalars['String']['output'];
  currency: Scalars['String']['output'];
  dateCreated: Scalars['DateTime']['output'];
  erpId: Scalars['String']['output'];
  erpPriceGroupId?: Maybe<Scalars['String']['output']>;
  history: Array<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  numberOfBillingAddresses: Scalars['Int']['output'];
  numberOfContacts: Scalars['Int']['output'];
  numberOfLocations: Scalars['Int']['output'];
  numberOfNotes: Scalars['Int']['output'];
  numberOfShipFromLocations: Scalars['Int']['output'];
  status: CompanyStatus;
  tenantId: Scalars['String']['output'];
};

export type CompaniesSummaryDocumentFilterInput = {
  and?: InputMaybe<Array<CompaniesSummaryDocumentFilterInput>>;
  companyId?: InputMaybe<StringOperationFilterInput>;
  currency?: InputMaybe<StringOperationFilterInput>;
  dateCreated?: InputMaybe<DateTimeOperationFilterInput>;
  erpId?: InputMaybe<StringOperationFilterInput>;
  erpPriceGroupId?: InputMaybe<StringOperationFilterInput>;
  history?: InputMaybe<ListStringOperationFilterInput>;
  id?: InputMaybe<StringOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  numberOfBillingAddresses?: InputMaybe<IntOperationFilterInput>;
  numberOfContacts?: InputMaybe<IntOperationFilterInput>;
  numberOfLocations?: InputMaybe<IntOperationFilterInput>;
  numberOfNotes?: InputMaybe<IntOperationFilterInput>;
  numberOfShipFromLocations?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<CompaniesSummaryDocumentFilterInput>>;
  status?: InputMaybe<CompanyStatusOperationFilterInput>;
  tenantId?: InputMaybe<StringOperationFilterInput>;
};

export type CompaniesSummaryDocumentSortInput = {
  companyId?: InputMaybe<SortEnumType>;
  currency?: InputMaybe<SortEnumType>;
  dateCreated?: InputMaybe<SortEnumType>;
  erpId?: InputMaybe<SortEnumType>;
  erpPriceGroupId?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  numberOfBillingAddresses?: InputMaybe<SortEnumType>;
  numberOfContacts?: InputMaybe<SortEnumType>;
  numberOfLocations?: InputMaybe<SortEnumType>;
  numberOfNotes?: InputMaybe<SortEnumType>;
  numberOfShipFromLocations?: InputMaybe<SortEnumType>;
  position?: InputMaybe<SortEnumType>;
  status?: InputMaybe<SortEnumType>;
  streamPosition?: InputMaybe<SortEnumType>;
  tenantId?: InputMaybe<SortEnumType>;
};

export type CompanyErpIdPayload = {
  __typename?: 'CompanyErpIdPayload';
  companyId: Scalars['String']['output'];
  erpId: Scalars['String']['output'];
  errorMessage: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type CompanyPayload = {
  __typename?: 'CompanyPayload';
  company?: Maybe<CompanyStateData>;
  errors?: Maybe<Array<ValidationError>>;
};

export type CompanyStateData = {
  __typename?: 'CompanyStateData';
  billingAddresses: Array<AddressData>;
  companyId: Scalars['String']['output'];
  contacts: Array<ContactData>;
  currency: Scalars['String']['output'];
  description: Scalars['String']['output'];
  erpId: Scalars['String']['output'];
  erpPriceGroupId: Scalars['String']['output'];
  locations: Array<LocationData>;
  name: Scalars['String']['output'];
  notes: Array<NoteData>;
  orderSubmitter?: Maybe<OrderSubmitterData>;
  shipFromLocations: Array<ShipFromLocationData>;
  status: CompanyStatus;
  tenantId: Scalars['String']['output'];
};

export enum CompanyStatus {
  Active = 'ACTIVE',
  Deleted = 'DELETED',
  Inactive = 'INACTIVE',
  Initialized = 'INITIALIZED',
  Unknown = 'UNKNOWN'
}

export type CompanyStatusOperationFilterInput = {
  eq?: InputMaybe<CompanyStatus>;
  in?: InputMaybe<Array<CompanyStatus>>;
  neq?: InputMaybe<CompanyStatus>;
  nin?: InputMaybe<Array<CompanyStatus>>;
};

export type ConfirmationEmailData = {
  __typename?: 'ConfirmationEmailData';
  sent: Scalars['DateTime']['output'];
  toEmail: Scalars['String']['output'];
};

export type ConfirmationEmailDataFilterInput = {
  and?: InputMaybe<Array<ConfirmationEmailDataFilterInput>>;
  or?: InputMaybe<Array<ConfirmationEmailDataFilterInput>>;
  sent?: InputMaybe<DateTimeOperationFilterInput>;
  toEmail?: InputMaybe<StringOperationFilterInput>;
};

export type ConfirmationEmailDataSortInput = {
  sent?: InputMaybe<SortEnumType>;
  toEmail?: InputMaybe<SortEnumType>;
};

export type ContactData = {
  __typename?: 'ContactData';
  emailAddresses: Array<EmailAddressData>;
  firstName: Scalars['String']['output'];
  id: Scalars['UUID']['output'];
  lastName: Scalars['String']['output'];
  phoneNumbers: Array<PhoneNumberData>;
  title: Scalars['String']['output'];
  type: ContactType;
};

export type ContactDataInput = {
  emailAddresses: Array<EmailAddressDataInput>;
  firstName: Scalars['String']['input'];
  id: Scalars['UUID']['input'];
  lastName: Scalars['String']['input'];
  phoneNumbers: Array<PhoneNumberDataInput>;
  title: Scalars['String']['input'];
  type: ContactType;
};

export enum ContactType {
  Other = 'OTHER',
  Primary = 'PRIMARY',
  Sales = 'SALES',
  Support = 'SUPPORT',
  Unknown = 'UNKNOWN'
}

export type CountDataInput = {
  comment: Scalars['String']['input'];
  grade: Scalars['Int']['input'];
  itemId: Scalars['String']['input'];
  locationId: Scalars['String']['input'];
  quantity: Scalars['Int']['input'];
  recordedAt: Scalars['DateTime']['input'];
  recordedBy: Scalars['String']['input'];
};

export type CreateCartInput = {
  billToAddressCity: Scalars['String']['input'];
  billToAddressCountry: Scalars['String']['input'];
  billToAddressId: Scalars['String']['input'];
  billToAddressLine1: Scalars['String']['input'];
  billToAddressLine2: Scalars['String']['input'];
  billToAddressStateProvince: Scalars['String']['input'];
  billToAddressToName: Scalars['String']['input'];
  billToAddressZipcode: Scalars['String']['input'];
  cartId: Scalars['String']['input'];
  companyId: Scalars['String']['input'];
  companyName: Scalars['String']['input'];
  expectedDeliveryDate: Scalars['DateTime']['input'];
  shipToAddressCity: Scalars['String']['input'];
  shipToAddressCountry: Scalars['String']['input'];
  shipToAddressId: Scalars['String']['input'];
  shipToAddressLine1: Scalars['String']['input'];
  shipToAddressLine2: Scalars['String']['input'];
  shipToAddressStateProvince: Scalars['String']['input'];
  shipToAddressToName: Scalars['String']['input'];
  shipToAddressZipcode: Scalars['String']['input'];
  shopperEmail: Scalars['String']['input'];
  shopperFirstName: Scalars['String']['input'];
  shopperId: Scalars['String']['input'];
  shopperLastName: Scalars['String']['input'];
  shopperPhone: Scalars['String']['input'];
  tenantId: Scalars['String']['input'];
};

export type DateTimeOperationFilterInput = {
  eq?: InputMaybe<Scalars['DateTime']['input']>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  neq?: InputMaybe<Scalars['DateTime']['input']>;
  ngt?: InputMaybe<Scalars['DateTime']['input']>;
  ngte?: InputMaybe<Scalars['DateTime']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  nlt?: InputMaybe<Scalars['DateTime']['input']>;
  nlte?: InputMaybe<Scalars['DateTime']['input']>;
};

export type DecimalOperationFilterInput = {
  eq?: InputMaybe<Scalars['Decimal']['input']>;
  gt?: InputMaybe<Scalars['Decimal']['input']>;
  gte?: InputMaybe<Scalars['Decimal']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Decimal']['input']>>>;
  lt?: InputMaybe<Scalars['Decimal']['input']>;
  lte?: InputMaybe<Scalars['Decimal']['input']>;
  neq?: InputMaybe<Scalars['Decimal']['input']>;
  ngt?: InputMaybe<Scalars['Decimal']['input']>;
  ngte?: InputMaybe<Scalars['Decimal']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['Decimal']['input']>>>;
  nlt?: InputMaybe<Scalars['Decimal']['input']>;
  nlte?: InputMaybe<Scalars['Decimal']['input']>;
};

export type DeleteCompanyInput = {
  companyId: Scalars['String']['input'];
  name: Scalars['String']['input'];
  tenantId: Scalars['String']['input'];
};

export type DeleteCountBatchInput = {
  countRowIds: Array<Scalars['String']['input']>;
  recordedAt: Scalars['DateTime']['input'];
  recordedBy: Scalars['String']['input'];
  sessionId: Scalars['String']['input'];
  tenantId: Scalars['String']['input'];
};

export type DeleteCountInput = {
  countRowId: Scalars['String']['input'];
  recordedAt: Scalars['DateTime']['input'];
  recordedBy: Scalars['String']['input'];
  sessionId: Scalars['String']['input'];
  tenantId: Scalars['String']['input'];
};

export type DeleteItemInput = {
  itemId: Scalars['String']['input'];
  name: Scalars['String']['input'];
  tenantId: Scalars['String']['input'];
};

export type DeleteTenantIntegrationInput = {
  entityId: Scalars['String']['input'];
  integrationId: Scalars['String']['input'];
};

export type EditCompanyInput = {
  companyId: Scalars['String']['input'];
  currency: Scalars['String']['input'];
  description: Scalars['String']['input'];
  erpId: Scalars['String']['input'];
  erpPriceGroupId: Scalars['String']['input'];
  name: Scalars['String']['input'];
  tenantId: Scalars['String']['input'];
};

export type EditOrderInput = {
  company: OrderCompanyDataInput;
  expectedDeliveryDate: Scalars['DateTime']['input'];
  fromLocation: ShipFromLocationDataInput;
  orderId: Scalars['String']['input'];
  orderNumber: Scalars['String']['input'];
  poNumber: Scalars['String']['input'];
  tenantId: Scalars['String']['input'];
  type: OrderType;
};

export type EmailAddressData = {
  __typename?: 'EmailAddressData';
  type: EmailAddressType;
  value: Scalars['String']['output'];
};

export type EmailAddressDataFilterInput = {
  and?: InputMaybe<Array<EmailAddressDataFilterInput>>;
  or?: InputMaybe<Array<EmailAddressDataFilterInput>>;
  type?: InputMaybe<EmailAddressTypeOperationFilterInput>;
  value?: InputMaybe<StringOperationFilterInput>;
};

export type EmailAddressDataInput = {
  type: EmailAddressType;
  value: Scalars['String']['input'];
};

export enum EmailAddressType {
  Home = 'HOME',
  Other = 'OTHER',
  Unknown = 'UNKNOWN',
  Work = 'WORK'
}

export type EmailAddressTypeOperationFilterInput = {
  eq?: InputMaybe<EmailAddressType>;
  in?: InputMaybe<Array<EmailAddressType>>;
  neq?: InputMaybe<EmailAddressType>;
  nin?: InputMaybe<Array<EmailAddressType>>;
};

export enum EntityType {
  Company = 'COMPANY',
  CompanyCleaner = 'COMPANY_CLEANER',
  ConfirmationEmail = 'CONFIRMATION_EMAIL',
  Item = 'ITEM',
  ItemCleaner = 'ITEM_CLEANER',
  ItemPricing = 'ITEM_PRICING',
  Order = 'ORDER',
  SalesPeople = 'SALES_PEOPLE'
}

export type EntityTypeOperationFilterInput = {
  eq?: InputMaybe<EntityType>;
  in?: InputMaybe<Array<EntityType>>;
  neq?: InputMaybe<EntityType>;
  nin?: InputMaybe<Array<EntityType>>;
};

export type ErpItemPriceData = {
  __typename?: 'ErpItemPriceData';
  containerQuantity: Scalars['Int']['output'];
  endDate: Scalars['DateTime']['output'];
  erpItemId: Scalars['String']['output'];
  startDate: Scalars['DateTime']['output'];
  unitPrice: Scalars['Decimal']['output'];
};

export type ErpItemPricingDocument = {
  __typename?: 'ErpItemPricingDocument';
  containerQuantity: Scalars['Int']['output'];
  endDate: Scalars['DateTime']['output'];
  erpItemId: Scalars['String']['output'];
  erpItemPricingId: Scalars['String']['output'];
  erpPriceGroupId: Scalars['String']['output'];
  startDate: Scalars['DateTime']['output'];
  tenantId: Scalars['String']['output'];
  unitPrice: Scalars['Decimal']['output'];
};

export type ErpItemPricingDocumentFilterInput = {
  and?: InputMaybe<Array<ErpItemPricingDocumentFilterInput>>;
  containerQuantity?: InputMaybe<IntOperationFilterInput>;
  endDate?: InputMaybe<DateTimeOperationFilterInput>;
  erpItemId?: InputMaybe<StringOperationFilterInput>;
  erpItemPricingId?: InputMaybe<StringOperationFilterInput>;
  erpPriceGroupId?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<ErpItemPricingDocumentFilterInput>>;
  startDate?: InputMaybe<DateTimeOperationFilterInput>;
  tenantId?: InputMaybe<StringOperationFilterInput>;
  unitPrice?: InputMaybe<DecimalOperationFilterInput>;
};

export type ErpItemPricingDocumentSortInput = {
  containerQuantity?: InputMaybe<SortEnumType>;
  endDate?: InputMaybe<SortEnumType>;
  erpItemId?: InputMaybe<SortEnumType>;
  erpItemPricingId?: InputMaybe<SortEnumType>;
  erpPriceGroupId?: InputMaybe<SortEnumType>;
  startDate?: InputMaybe<SortEnumType>;
  tenantId?: InputMaybe<SortEnumType>;
  unitPrice?: InputMaybe<SortEnumType>;
};

export type ErpItemPricingPayload = {
  __typename?: 'ErpItemPricingPayload';
  erpItemPrice?: Maybe<ErpItemPricingStateData>;
  errors?: Maybe<Array<ValidationError>>;
};

export type ErpItemPricingStateData = {
  __typename?: 'ErpItemPricingStateData';
  erpPriceGroupId: Scalars['String']['output'];
  itemPrices: Array<ErpItemPriceData>;
  startYear: Scalars['Int']['output'];
  tenantId: Scalars['String']['output'];
};

export type EventRecordData = {
  __typename?: 'EventRecordData';
  contentType: Scalars['String']['output'];
  created: Scalars['DateTime']['output'];
  data: Scalars['String']['output'];
  eventId: Scalars['String']['output'];
  eventNumber: Scalars['Long']['output'];
  eventStreamId: Scalars['String']['output'];
  eventType: Scalars['String']['output'];
  metadata: Scalars['String']['output'];
  position: PositionData;
};

export type InactivateCompanyInput = {
  companyId: Scalars['String']['input'];
  name: Scalars['String']['input'];
  tenantId: Scalars['String']['input'];
};

export type InactivateItemInput = {
  itemId: Scalars['String']['input'];
  sku: Scalars['String']['input'];
  tenantId: Scalars['String']['input'];
};

export type InactivateTenantInput = {
  entityId: Scalars['String']['input'];
};

export type InitializeOrderInput = {
  company: OrderCompanyDataInput;
  expectedDeliveryDate: Scalars['DateTime']['input'];
  fromLocation: ShipFromLocationDataInput;
  orderId: Scalars['String']['input'];
  orderNumber: Scalars['String']['input'];
  poNumber: Scalars['String']['input'];
  salesPersonEmail?: InputMaybe<Scalars['String']['input']>;
  tenantId: Scalars['String']['input'];
  type: OrderType;
};

export type IntOperationFilterInput = {
  eq?: InputMaybe<Scalars['Int']['input']>;
  gt?: InputMaybe<Scalars['Int']['input']>;
  gte?: InputMaybe<Scalars['Int']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  lt?: InputMaybe<Scalars['Int']['input']>;
  lte?: InputMaybe<Scalars['Int']['input']>;
  neq?: InputMaybe<Scalars['Int']['input']>;
  ngt?: InputMaybe<Scalars['Int']['input']>;
  ngte?: InputMaybe<Scalars['Int']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  nlt?: InputMaybe<Scalars['Int']['input']>;
  nlte?: InputMaybe<Scalars['Int']['input']>;
};

export enum IntegrationSource {
  QuickBooks = 'QUICK_BOOKS',
  Unknown = 'UNKNOWN'
}

export type IntegrationSourceOperationFilterInput = {
  eq?: InputMaybe<IntegrationSource>;
  in?: InputMaybe<Array<IntegrationSource>>;
  neq?: InputMaybe<IntegrationSource>;
  nin?: InputMaybe<Array<IntegrationSource>>;
};

export type InventoryAvailabilityData = {
  __typename?: 'InventoryAvailabilityData';
  comment: Scalars['String']['output'];
  locationCode: Scalars['String']['output'];
  locationId: Scalars['String']['output'];
  quantity: Scalars['Int']['output'];
  recordedAt: Scalars['DateTime']['output'];
  recordedBy: Scalars['String']['output'];
};

export type ItemAvailabilityDocument = {
  __typename?: 'ItemAvailabilityDocument';
  id: Scalars['String']['output'];
  itemId: Scalars['String']['output'];
  itemName: Scalars['String']['output'];
  quantity: Scalars['Int']['output'];
  recordedAt: Scalars['DateTime']['output'];
  sku: Scalars['String']['output'];
  tenantId: Scalars['String']['output'];
  upc: Scalars['String']['output'];
};

export type ItemAvailabilityDocumentFilterInput = {
  and?: InputMaybe<Array<ItemAvailabilityDocumentFilterInput>>;
  id?: InputMaybe<StringOperationFilterInput>;
  itemId?: InputMaybe<StringOperationFilterInput>;
  itemName?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<ItemAvailabilityDocumentFilterInput>>;
  quantity?: InputMaybe<IntOperationFilterInput>;
  recordedAt?: InputMaybe<DateTimeOperationFilterInput>;
  sku?: InputMaybe<StringOperationFilterInput>;
  tenantId?: InputMaybe<StringOperationFilterInput>;
  upc?: InputMaybe<StringOperationFilterInput>;
};

export type ItemAvailabilityDocumentSortInput = {
  id?: InputMaybe<SortEnumType>;
  itemId?: InputMaybe<SortEnumType>;
  itemName?: InputMaybe<SortEnumType>;
  quantity?: InputMaybe<SortEnumType>;
  recordedAt?: InputMaybe<SortEnumType>;
  sku?: InputMaybe<SortEnumType>;
  tenantId?: InputMaybe<SortEnumType>;
  upc?: InputMaybe<SortEnumType>;
};

export type ItemConfigurationData = {
  __typename?: 'ItemConfigurationData';
  carrier: CarrierData;
  maxLayers: Scalars['Int']['output'];
  unitsPerLayer: Scalars['Int']['output'];
  uom: UnitOfMeasureData;
};

export type ItemDocumentSize = {
  __typename?: 'ItemDocumentSize';
  code: Scalars['String']['output'];
  eaches: Scalars['Int']['output'];
  uom: Scalars['Int']['output'];
};

export type ItemDocumentSizeFilterInput = {
  and?: InputMaybe<Array<ItemDocumentSizeFilterInput>>;
  code?: InputMaybe<StringOperationFilterInput>;
  eaches?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<ItemDocumentSizeFilterInput>>;
  uom?: InputMaybe<IntOperationFilterInput>;
};

export type ItemErpIdPayload = {
  __typename?: 'ItemErpIdPayload';
  erpId: Scalars['String']['output'];
  errorMessage: Scalars['String']['output'];
  itemId: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type ItemLocationAvailabilityDocument = {
  __typename?: 'ItemLocationAvailabilityDocument';
  comment: Scalars['String']['output'];
  id: Scalars['String']['output'];
  itemId: Scalars['String']['output'];
  itemName: Scalars['String']['output'];
  locationId: Scalars['String']['output'];
  quantity: Scalars['Int']['output'];
  recordedAt: Scalars['DateTime']['output'];
  recordedBy: Scalars['String']['output'];
  sku: Scalars['String']['output'];
  tenantId: Scalars['String']['output'];
  upc: Scalars['String']['output'];
};

export type ItemLocationAvailabilityDocumentFilterInput = {
  and?: InputMaybe<Array<ItemLocationAvailabilityDocumentFilterInput>>;
  comment?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<StringOperationFilterInput>;
  itemId?: InputMaybe<StringOperationFilterInput>;
  itemName?: InputMaybe<StringOperationFilterInput>;
  locationId?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<ItemLocationAvailabilityDocumentFilterInput>>;
  quantity?: InputMaybe<IntOperationFilterInput>;
  recordedAt?: InputMaybe<DateTimeOperationFilterInput>;
  recordedBy?: InputMaybe<StringOperationFilterInput>;
  sku?: InputMaybe<StringOperationFilterInput>;
  tenantId?: InputMaybe<StringOperationFilterInput>;
  upc?: InputMaybe<StringOperationFilterInput>;
};

export type ItemLocationAvailabilityDocumentSortInput = {
  comment?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  itemId?: InputMaybe<SortEnumType>;
  itemName?: InputMaybe<SortEnumType>;
  locationId?: InputMaybe<SortEnumType>;
  quantity?: InputMaybe<SortEnumType>;
  recordedAt?: InputMaybe<SortEnumType>;
  recordedBy?: InputMaybe<SortEnumType>;
  sku?: InputMaybe<SortEnumType>;
  tenantId?: InputMaybe<SortEnumType>;
  upc?: InputMaybe<SortEnumType>;
};

export type ItemPayload = {
  __typename?: 'ItemPayload';
  errors?: Maybe<Array<ValidationError>>;
  item?: Maybe<ItemStateData>;
};

export type ItemPriceInput = {
  containerQuantity: Scalars['Int']['input'];
  endDate: Scalars['DateTime']['input'];
  erpItemId: Scalars['String']['input'];
  startDate: Scalars['DateTime']['input'];
  unitPrice: Scalars['Decimal']['input'];
};

export type ItemProgramsDocument = {
  __typename?: 'ItemProgramsDocument';
  id: Scalars['String']['output'];
  programs: Array<Scalars['String']['output']>;
  tenantId: Scalars['String']['output'];
};

export type ItemProgramsDocumentFilterInput = {
  and?: InputMaybe<Array<ItemProgramsDocumentFilterInput>>;
  id?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<ItemProgramsDocumentFilterInput>>;
  programs?: InputMaybe<ListStringOperationFilterInput>;
  tenantId?: InputMaybe<StringOperationFilterInput>;
};

export type ItemProgramsDocumentSortInput = {
  id?: InputMaybe<SortEnumType>;
  position?: InputMaybe<SortEnumType>;
  streamPosition?: InputMaybe<SortEnumType>;
  tenantId?: InputMaybe<SortEnumType>;
};

export type ItemStateData = {
  __typename?: 'ItemStateData';
  availability: Array<InventoryAvailabilityData>;
  categories: Array<CategoryData>;
  configurations: Array<ItemConfigurationData>;
  description: Scalars['String']['output'];
  erpId: Scalars['String']['output'];
  imageUrl?: Maybe<Scalars['String']['output']>;
  itemGroup: Scalars['String']['output'];
  itemId: Scalars['String']['output'];
  name: Scalars['String']['output'];
  programs: Array<Scalars['String']['output']>;
  sellPrice?: Maybe<Scalars['Decimal']['output']>;
  sku: Scalars['String']['output'];
  status: ItemStatus;
  tenantId: Scalars['String']['output'];
  upc: Scalars['String']['output'];
};

export enum ItemStatus {
  Active = 'ACTIVE',
  Deleted = 'DELETED',
  Inactive = 'INACTIVE',
  Unknown = 'UNKNOWN'
}

export type ItemStatusOperationFilterInput = {
  eq?: InputMaybe<ItemStatus>;
  in?: InputMaybe<Array<ItemStatus>>;
  neq?: InputMaybe<ItemStatus>;
  nin?: InputMaybe<Array<ItemStatus>>;
};

export type ItemSummaryDocument = {
  __typename?: 'ItemSummaryDocument';
  availableQuantity?: Maybe<Scalars['Int']['output']>;
  categories?: Maybe<Array<Scalars['String']['output']>>;
  description: Scalars['String']['output'];
  erpId: Scalars['String']['output'];
  group: Scalars['String']['output'];
  history: Array<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  imageUrl?: Maybe<Scalars['String']['output']>;
  itemId: Scalars['String']['output'];
  name: Scalars['String']['output'];
  programs?: Maybe<Array<Scalars['String']['output']>>;
  sellPrice?: Maybe<Scalars['Decimal']['output']>;
  sizes?: Maybe<Array<ItemDocumentSize>>;
  sku: Scalars['String']['output'];
  sortCategory?: Maybe<Scalars['String']['output']>;
  status: ItemStatus;
  tenantId: Scalars['String']['output'];
  upc: Scalars['String']['output'];
};

export type ItemSummaryDocumentFilterInput = {
  and?: InputMaybe<Array<ItemSummaryDocumentFilterInput>>;
  availableQuantity?: InputMaybe<IntOperationFilterInput>;
  categories?: InputMaybe<ListStringOperationFilterInput>;
  description?: InputMaybe<StringOperationFilterInput>;
  erpId?: InputMaybe<StringOperationFilterInput>;
  group?: InputMaybe<StringOperationFilterInput>;
  history?: InputMaybe<ListStringOperationFilterInput>;
  id?: InputMaybe<StringOperationFilterInput>;
  imageUrl?: InputMaybe<StringOperationFilterInput>;
  itemId?: InputMaybe<StringOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<ItemSummaryDocumentFilterInput>>;
  programs?: InputMaybe<ListStringOperationFilterInput>;
  sellPrice?: InputMaybe<DecimalOperationFilterInput>;
  sizes?: InputMaybe<ListFilterInputTypeOfItemDocumentSizeFilterInput>;
  sku?: InputMaybe<StringOperationFilterInput>;
  sortCategory?: InputMaybe<StringOperationFilterInput>;
  status?: InputMaybe<ItemStatusOperationFilterInput>;
  tenantId?: InputMaybe<StringOperationFilterInput>;
  upc?: InputMaybe<StringOperationFilterInput>;
};

export type ItemSummaryDocumentSortInput = {
  availableQuantity?: InputMaybe<SortEnumType>;
  description?: InputMaybe<SortEnumType>;
  erpId?: InputMaybe<SortEnumType>;
  group?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  imageUrl?: InputMaybe<SortEnumType>;
  itemId?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  position?: InputMaybe<SortEnumType>;
  sellPrice?: InputMaybe<SortEnumType>;
  sku?: InputMaybe<SortEnumType>;
  sortCategory?: InputMaybe<SortEnumType>;
  status?: InputMaybe<SortEnumType>;
  streamPosition?: InputMaybe<SortEnumType>;
  tenantId?: InputMaybe<SortEnumType>;
  upc?: InputMaybe<SortEnumType>;
};

/** A connection to a list of items. */
export type ItemsConnection = {
  __typename?: 'ItemsConnection';
  /** A list of edges. */
  edges?: Maybe<Array<ItemsEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<ItemSummaryDocument>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type ItemsEdge = {
  __typename?: 'ItemsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: ItemSummaryDocument;
};

export type IterationDocument = {
  __typename?: 'IterationDocument';
  description: Scalars['String']['output'];
  end: Scalars['DateTime']['output'];
  entity: EntityType;
  erpBatchSize: Scalars['Int']['output'];
  erpFetchMs: Scalars['Long']['output'];
  error: Scalars['String']['output'];
  id: Scalars['ObjectId']['output'];
  items: Array<IterationItem>;
  itemsProcessed: Scalars['Long']['output'];
  retries: Scalars['Int']['output'];
  skippedItems: Array<IterationItem>;
  start: Scalars['DateTime']['output'];
  success: Scalars['Boolean']['output'];
  tenantId: Scalars['String']['output'];
  totalMs: Scalars['Long']['output'];
  workSuiteProcessMs: Scalars['Long']['output'];
};

export type IterationDocumentFilterInput = {
  and?: InputMaybe<Array<IterationDocumentFilterInput>>;
  description?: InputMaybe<StringOperationFilterInput>;
  end?: InputMaybe<DateTimeOperationFilterInput>;
  entity?: InputMaybe<EntityTypeOperationFilterInput>;
  erpBatchSize?: InputMaybe<IntOperationFilterInput>;
  erpFetchMs?: InputMaybe<LongOperationFilterInput>;
  error?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<ObjectIdOperationFilterInput>;
  items?: InputMaybe<ListFilterInputTypeOfIterationItemFilterInput>;
  itemsProcessed?: InputMaybe<LongOperationFilterInput>;
  or?: InputMaybe<Array<IterationDocumentFilterInput>>;
  retries?: InputMaybe<IntOperationFilterInput>;
  skippedItems?: InputMaybe<ListFilterInputTypeOfIterationItemFilterInput>;
  start?: InputMaybe<DateTimeOperationFilterInput>;
  success?: InputMaybe<BooleanOperationFilterInput>;
  tenantId?: InputMaybe<StringOperationFilterInput>;
  totalMs?: InputMaybe<LongOperationFilterInput>;
  workSuiteProcessMs?: InputMaybe<LongOperationFilterInput>;
};

export type IterationDocumentSortInput = {
  description?: InputMaybe<SortEnumType>;
  end?: InputMaybe<SortEnumType>;
  entity?: InputMaybe<SortEnumType>;
  erpBatchSize?: InputMaybe<SortEnumType>;
  erpFetchMs?: InputMaybe<SortEnumType>;
  error?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  itemsProcessed?: InputMaybe<SortEnumType>;
  retries?: InputMaybe<SortEnumType>;
  start?: InputMaybe<SortEnumType>;
  success?: InputMaybe<SortEnumType>;
  tenantId?: InputMaybe<SortEnumType>;
  totalMs?: InputMaybe<SortEnumType>;
  workSuiteProcessMs?: InputMaybe<SortEnumType>;
};

export type IterationItem = {
  __typename?: 'IterationItem';
  description: Scalars['String']['output'];
  key: Scalars['String']['output'];
  reason: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type IterationItemFilterInput = {
  and?: InputMaybe<Array<IterationItemFilterInput>>;
  description?: InputMaybe<StringOperationFilterInput>;
  key?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<IterationItemFilterInput>>;
  reason?: InputMaybe<StringOperationFilterInput>;
  value?: InputMaybe<StringOperationFilterInput>;
};

export type LineItemData = {
  __typename?: 'LineItemData';
  addedBy: Scalars['String']['output'];
  cost: Scalars['Decimal']['output'];
  currency: Scalars['String']['output'];
  dateAdded: Scalars['DateTime']['output'];
  eachesQuantity?: Maybe<Scalars['Int']['output']>;
  id: Scalars['UUID']['output'];
  itemGroup?: Maybe<Scalars['String']['output']>;
  itemId: Scalars['String']['output'];
  lineNumber: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  overridePrice?: Maybe<Scalars['Decimal']['output']>;
  price: Scalars['Decimal']['output'];
  quantity?: Maybe<Scalars['Int']['output']>;
  sku: Scalars['String']['output'];
  upc: Scalars['String']['output'];
};

export type LineItemDataInput = {
  addedBy: Scalars['String']['input'];
  cost: Scalars['Decimal']['input'];
  currency: Scalars['String']['input'];
  dateAdded: Scalars['DateTime']['input'];
  eachesQuantity?: InputMaybe<Scalars['Int']['input']>;
  id: Scalars['UUID']['input'];
  itemGroup?: InputMaybe<Scalars['String']['input']>;
  itemId: Scalars['String']['input'];
  lineNumber: Scalars['Int']['input'];
  name: Scalars['String']['input'];
  overridePrice?: InputMaybe<Scalars['Decimal']['input']>;
  price: Scalars['Decimal']['input'];
  quantity?: InputMaybe<Scalars['Int']['input']>;
  sku: Scalars['String']['input'];
  upc: Scalars['String']['input'];
};

export type LineItemUpdateDataInput = {
  department: Scalars['String']['input'];
  notes: Array<Scalars['String']['input']>;
  quantity: Scalars['Int']['input'];
};

export type ListFilterInputTypeOfItemDocumentSizeFilterInput = {
  all?: InputMaybe<ItemDocumentSizeFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<ItemDocumentSizeFilterInput>;
  some?: InputMaybe<ItemDocumentSizeFilterInput>;
};

export type ListFilterInputTypeOfIterationItemFilterInput = {
  all?: InputMaybe<IterationItemFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<IterationItemFilterInput>;
  some?: InputMaybe<IterationItemFilterInput>;
};

export type ListFilterInputTypeOfSalesPersonDataFilterInput = {
  all?: InputMaybe<SalesPersonDataFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<SalesPersonDataFilterInput>;
  some?: InputMaybe<SalesPersonDataFilterInput>;
};

export type ListStringOperationFilterInput = {
  all?: InputMaybe<StringOperationFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<StringOperationFilterInput>;
  some?: InputMaybe<StringOperationFilterInput>;
};

export type LocationData = {
  __typename?: 'LocationData';
  adGroup: Scalars['String']['output'];
  address: AddressData;
  code: Scalars['String']['output'];
  description: Scalars['String']['output'];
  globalLocationNumber: Scalars['String']['output'];
  id: Scalars['UUID']['output'];
  market: Scalars['String']['output'];
  name: Scalars['String']['output'];
  region: Scalars['String']['output'];
  salesPeople: Array<SalesPersonData>;
  scopeId: Scalars['String']['output'];
  storeNumber: Scalars['String']['output'];
};

export type LocationDataFilterInput = {
  adGroup?: InputMaybe<StringOperationFilterInput>;
  address?: InputMaybe<AddressDataFilterInput>;
  and?: InputMaybe<Array<LocationDataFilterInput>>;
  code?: InputMaybe<StringOperationFilterInput>;
  description?: InputMaybe<StringOperationFilterInput>;
  globalLocationNumber?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  market?: InputMaybe<StringOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<LocationDataFilterInput>>;
  region?: InputMaybe<StringOperationFilterInput>;
  salesPeople?: InputMaybe<ListFilterInputTypeOfSalesPersonDataFilterInput>;
  scopeId?: InputMaybe<StringOperationFilterInput>;
  storeNumber?: InputMaybe<StringOperationFilterInput>;
};

export type LocationDataInput = {
  adGroup: Scalars['String']['input'];
  address: AddressDataInput;
  code: Scalars['String']['input'];
  description: Scalars['String']['input'];
  globalLocationNumber: Scalars['String']['input'];
  id: Scalars['UUID']['input'];
  market: Scalars['String']['input'];
  name: Scalars['String']['input'];
  region: Scalars['String']['input'];
  salesPeople: Array<SalesPersonDataInput>;
  scopeId: Scalars['String']['input'];
  storeNumber: Scalars['String']['input'];
};

export type LocationDataSortInput = {
  adGroup?: InputMaybe<SortEnumType>;
  address?: InputMaybe<AddressDataSortInput>;
  code?: InputMaybe<SortEnumType>;
  description?: InputMaybe<SortEnumType>;
  globalLocationNumber?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  market?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  region?: InputMaybe<SortEnumType>;
  scopeId?: InputMaybe<SortEnumType>;
  storeNumber?: InputMaybe<SortEnumType>;
};

/** A connection to a list of items. */
export type LocationsSalesPeopleConnection = {
  __typename?: 'LocationsSalesPeopleConnection';
  /** A list of edges. */
  edges?: Maybe<Array<LocationsSalesPeopleEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<LocationsSalesPersonDocument>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type LocationsSalesPeopleEdge = {
  __typename?: 'LocationsSalesPeopleEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: LocationsSalesPersonDocument;
};

export type LocationsSalesPersonDocument = {
  __typename?: 'LocationsSalesPersonDocument';
  code: Scalars['String']['output'];
  companyId: Scalars['String']['output'];
  erpId: Scalars['String']['output'];
  id: Scalars['String']['output'];
  locationId: Scalars['UUID']['output'];
  name: Scalars['String']['output'];
  salesPersonEmails: Array<Scalars['String']['output']>;
  tenantId: Scalars['String']['output'];
};

export type LocationsSalesPersonDocumentFilterInput = {
  and?: InputMaybe<Array<LocationsSalesPersonDocumentFilterInput>>;
  code?: InputMaybe<StringOperationFilterInput>;
  companyId?: InputMaybe<StringOperationFilterInput>;
  erpId?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<StringOperationFilterInput>;
  locationId?: InputMaybe<UuidOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<LocationsSalesPersonDocumentFilterInput>>;
  salesPersonEmails?: InputMaybe<ListStringOperationFilterInput>;
  tenantId?: InputMaybe<StringOperationFilterInput>;
};

export type LocationsSalesPersonDocumentSortInput = {
  code?: InputMaybe<SortEnumType>;
  companyId?: InputMaybe<SortEnumType>;
  erpId?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  locationId?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  position?: InputMaybe<SortEnumType>;
  streamPosition?: InputMaybe<SortEnumType>;
  tenantId?: InputMaybe<SortEnumType>;
};

export type LongOperationFilterInput = {
  eq?: InputMaybe<Scalars['Long']['input']>;
  gt?: InputMaybe<Scalars['Long']['input']>;
  gte?: InputMaybe<Scalars['Long']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Long']['input']>>>;
  lt?: InputMaybe<Scalars['Long']['input']>;
  lte?: InputMaybe<Scalars['Long']['input']>;
  neq?: InputMaybe<Scalars['Long']['input']>;
  ngt?: InputMaybe<Scalars['Long']['input']>;
  ngte?: InputMaybe<Scalars['Long']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['Long']['input']>>>;
  nlt?: InputMaybe<Scalars['Long']['input']>;
  nlte?: InputMaybe<Scalars['Long']['input']>;
};

export type MakeSessionLiveInput = {
  comment: Scalars['String']['input'];
  recordedAt: Scalars['DateTime']['input'];
  recordedBy: Scalars['String']['input'];
  sessionId: Scalars['String']['input'];
  tenantId: Scalars['String']['input'];
};

export type MergeCompanyInput = {
  billingAddress: AddressDataInput;
  companyId: Scalars['String']['input'];
  currency: Scalars['String']['input'];
  description: Scalars['String']['input'];
  erpId: Scalars['String']['input'];
  erpPriceGroupId: Scalars['String']['input'];
  location: LocationDataInput;
  name: Scalars['String']['input'];
  orderSubmitter?: InputMaybe<OrderSubmitterDataInput>;
  tenantId: Scalars['String']['input'];
};

export type MergeItemInput = {
  categories: Array<CategoryDataInput>;
  description: Scalars['String']['input'];
  erpId?: Scalars['String']['input'];
  itemGroup: Scalars['String']['input'];
  itemId: Scalars['String']['input'];
  name: Scalars['String']['input'];
  sku: Scalars['String']['input'];
  tenantId: Scalars['String']['input'];
  upc: Scalars['String']['input'];
};

export type MoneyData = {
  __typename?: 'MoneyData';
  amount: Scalars['Decimal']['output'];
  currency: Scalars['String']['output'];
};

export type MoneyDataFilterInput = {
  amount?: InputMaybe<DecimalOperationFilterInput>;
  and?: InputMaybe<Array<MoneyDataFilterInput>>;
  currency?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<MoneyDataFilterInput>>;
};

export type MoneyDataSortInput = {
  amount?: InputMaybe<SortEnumType>;
  currency?: InputMaybe<SortEnumType>;
};

export type Mutation = {
  __typename?: 'Mutation';
  activateCompany: CompanyPayload;
  activateItem: ItemPayload;
  activateTenant: TenantPayload;
  addAvailabilitySessionCount: AvailabilitySessionPayload;
  addBillingAddress: CompanyPayload;
  addCategory: CategoryPayload;
  addContact: CompanyPayload;
  addItem: ItemPayload;
  addItemAvailability: AvailabilitySessionPayload;
  addItemAvailabilityBatch: AvailabilitySessionPayload;
  addItemToShoppingCart: ShoppingCartPayload;
  addLineItems: OrderPayload;
  addLocation: CompanyPayload;
  addNote: CompanyPayload;
  addShipFromLocation: CompanyPayload;
  addSizes: SizesPayload;
  addTenant: TenantPayload;
  addUnitOfMeasure: UnitOfMeasurePayload;
  adjustAvailabilitySessionCounts: AvailabilitySessionPayload;
  cleanCompanies: Array<CompanyErpIdPayload>;
  cleanItems: Array<ItemErpIdPayload>;
  closeAvailabilitySession: AvailabilitySessionPayload;
  createCompany: CompanyPayload;
  createShoppingCart: ShoppingCartPayload;
  deleteAvailabilitySessionCount: AvailabilitySessionPayload;
  deleteAvailabilitySessionCountBatch: AvailabilitySessionPayload;
  deleteCompany: CompanyPayload;
  deleteItem: ItemPayload;
  deleteTenantIntegration: TenantPayload;
  editOrder: OrderPayload;
  inactivateCompany: CompanyPayload;
  inactivateItem: ItemPayload;
  inactivateTenant: TenantPayload;
  initializeOrder: OrderPayload;
  makeLiveAvailabilitySession: AvailabilitySessionPayload;
  mergeCompany: CompanyPayload;
  mergeItem: ItemPayload;
  recordAvailabilitySessionCounts: AvailabilitySessionPayload;
  removeBillingAddress: CompanyPayload;
  removeCategory: CategoryPayload;
  removeContact: CompanyPayload;
  removeLineItem: OrderPayload;
  removeLocation: CompanyPayload;
  removeSizes: SizesPayload;
  removeUnitOfMeasure: UnitOfMeasurePayload;
  renameTenant: TenantPayload;
  replaceSalesPeople: CompanyPayload;
  startAvailabilitySession: AvailabilitySessionPayload;
  synchronizeErpItemPrices: ErpItemPricingPayload;
  synchronizeSalesPeople: Array<CompanyPayload>;
  updateAndGetNextOrderNumber: NextOrderNumberPayload;
  updateAvailabilitySessionCount: AvailabilitySessionPayload;
  updateBillingAddress: CompanyPayload;
  updateCategory: CategoryPayload;
  updateCompany: CompanyPayload;
  updateConfirmationEmail: OrderPayload;
  updateContact: CompanyPayload;
  updateDefaultOrderSubmitter: CompanyPayload;
  updateItem: ItemPayload;
  updateItemImageUrl: ItemPayload;
  updateItemSellPrice: ItemPayload;
  updateLineItem: OrderPayload;
  updateLocation: CompanyPayload;
  updateMissingItemGroups: OrderPayload;
  updateOrderContact: OrderPayload;
  updateOrderStatus: OrderPayload;
  updateOrderSyncAttempt: OrderPayload;
  updateSalesPerson: OrderPayload;
  updateShoppingCart: ShoppingCartPayload;
  updateSizes: SizesPayload;
  updateUnitOfMeasure: UnitOfMeasurePayload;
  upsertTenantIntegration: TenantPayload;
};


export type MutationActivateCompanyArgs = {
  input: ActivateCompanyInput;
};


export type MutationActivateItemArgs = {
  input: ActivateItemInput;
};


export type MutationActivateTenantArgs = {
  input: ActivateTenantInput;
};


export type MutationAddAvailabilitySessionCountArgs = {
  input: AddCountInput;
};


export type MutationAddBillingAddressArgs = {
  input: AddBillingAddressInput;
};


export type MutationAddCategoryArgs = {
  input: AddCategoryInput;
};


export type MutationAddContactArgs = {
  input: AddContactInput;
};


export type MutationAddItemArgs = {
  input: AddItemInput;
};


export type MutationAddItemAvailabilityArgs = {
  input: AddItemAvailabilityInput;
};


export type MutationAddItemAvailabilityBatchArgs = {
  input: Array<AddItemAvailabilityInput>;
};


export type MutationAddItemToShoppingCartArgs = {
  input: AddItemToCartInput;
};


export type MutationAddLineItemsArgs = {
  input: AddLineItemsInput;
};


export type MutationAddLocationArgs = {
  input: AddLocationInput;
};


export type MutationAddNoteArgs = {
  input: AddNoteInput;
};


export type MutationAddShipFromLocationArgs = {
  input: AddShipFromLocationInput;
};


export type MutationAddSizesArgs = {
  input: AddSizesInput;
};


export type MutationAddTenantArgs = {
  input: AddTenantInput;
};


export type MutationAddUnitOfMeasureArgs = {
  input: AddUnitInput;
};


export type MutationAdjustAvailabilitySessionCountsArgs = {
  input: RecordAdjustmentInput;
};


export type MutationCleanCompaniesArgs = {
  input: CleanCompaniesInput;
};


export type MutationCleanItemsArgs = {
  input: CleanItemsInput;
};


export type MutationCloseAvailabilitySessionArgs = {
  input: CloseSessionInput;
};


export type MutationCreateCompanyArgs = {
  input: AddCompanyInput;
};


export type MutationCreateShoppingCartArgs = {
  input: CreateCartInput;
};


export type MutationDeleteAvailabilitySessionCountArgs = {
  input: DeleteCountInput;
};


export type MutationDeleteAvailabilitySessionCountBatchArgs = {
  input: DeleteCountBatchInput;
};


export type MutationDeleteCompanyArgs = {
  input: DeleteCompanyInput;
};


export type MutationDeleteItemArgs = {
  input: DeleteItemInput;
};


export type MutationDeleteTenantIntegrationArgs = {
  input: DeleteTenantIntegrationInput;
};


export type MutationEditOrderArgs = {
  input: EditOrderInput;
};


export type MutationInactivateCompanyArgs = {
  input: InactivateCompanyInput;
};


export type MutationInactivateItemArgs = {
  input: InactivateItemInput;
};


export type MutationInactivateTenantArgs = {
  input: InactivateTenantInput;
};


export type MutationInitializeOrderArgs = {
  input: InitializeOrderInput;
};


export type MutationMakeLiveAvailabilitySessionArgs = {
  input: MakeSessionLiveInput;
};


export type MutationMergeCompanyArgs = {
  input: MergeCompanyInput;
};


export type MutationMergeItemArgs = {
  input: MergeItemInput;
};


export type MutationRecordAvailabilitySessionCountsArgs = {
  input: RecordCountInput;
};


export type MutationRemoveBillingAddressArgs = {
  input: RemoveBillingAddressInput;
};


export type MutationRemoveCategoryArgs = {
  input: RemoveCategoryInput;
};


export type MutationRemoveContactArgs = {
  input: RemoveContactInput;
};


export type MutationRemoveLineItemArgs = {
  input: RemoveLineItemInput;
};


export type MutationRemoveLocationArgs = {
  input: RemoveLocationInput;
};


export type MutationRemoveSizesArgs = {
  input: RemoveSizesInput;
};


export type MutationRemoveUnitOfMeasureArgs = {
  input: RemoveUnitInput;
};


export type MutationRenameTenantArgs = {
  input: RenameTenantInput;
};


export type MutationReplaceSalesPeopleArgs = {
  input: ReplaceSalesPeopleInput;
};


export type MutationStartAvailabilitySessionArgs = {
  input: StartAvailabilitySessionInput;
};


export type MutationSynchronizeErpItemPricesArgs = {
  input: SynchronizeErpItemPricingInput;
};


export type MutationSynchronizeSalesPeopleArgs = {
  input: SynchronizeSalesPeopleInput;
};


export type MutationUpdateAndGetNextOrderNumberArgs = {
  input: UpdateAndGetOrderNumberInput;
};


export type MutationUpdateAvailabilitySessionCountArgs = {
  input: UpdateCountInput;
};


export type MutationUpdateBillingAddressArgs = {
  input: UpdateBillingAddressInput;
};


export type MutationUpdateCategoryArgs = {
  input: UpdateCategoryInput;
};


export type MutationUpdateCompanyArgs = {
  input: EditCompanyInput;
};


export type MutationUpdateConfirmationEmailArgs = {
  input: UpdateConfirmationEmailInput;
};


export type MutationUpdateContactArgs = {
  input: UpdateContactInput;
};


export type MutationUpdateDefaultOrderSubmitterArgs = {
  input: UpdateDefaultOrderSubmitterInput;
};


export type MutationUpdateItemArgs = {
  input: UpdateItemInput;
};


export type MutationUpdateItemImageUrlArgs = {
  input: UpdateImageUrlInput;
};


export type MutationUpdateItemSellPriceArgs = {
  input: UpdateSellPriceInput;
};


export type MutationUpdateLineItemArgs = {
  input: UpdateLineItemInput;
};


export type MutationUpdateLocationArgs = {
  input: UpdateLocationInput;
};


export type MutationUpdateMissingItemGroupsArgs = {
  input: UpdateMissingItemGroupsInput;
};


export type MutationUpdateOrderContactArgs = {
  input: UpdateOrderContactInput;
};


export type MutationUpdateOrderStatusArgs = {
  input: UpdateOrderStatusInput;
};


export type MutationUpdateOrderSyncAttemptArgs = {
  input: UpdateSyncAttemptInput;
};


export type MutationUpdateSalesPersonArgs = {
  input: UpdateSalesPersonInput;
};


export type MutationUpdateShoppingCartArgs = {
  input: UpdateCartInput;
};


export type MutationUpdateSizesArgs = {
  input: UpdateSizesInput;
};


export type MutationUpdateUnitOfMeasureArgs = {
  input: UpdateUnitInput;
};


export type MutationUpsertTenantIntegrationArgs = {
  input: UpsertTenantIntegrationInput;
};

export type NextOrderNumberPayload = {
  __typename?: 'NextOrderNumberPayload';
  nextOrderNumber: Scalars['Long']['output'];
};

export type NoteData = {
  __typename?: 'NoteData';
  id: Scalars['UUID']['output'];
  noteText: Scalars['String']['output'];
  submitDate: Scalars['DateTime']['output'];
  submittedBy: Scalars['String']['output'];
};

export type ObjectIdOperationFilterInput = {
  eq?: InputMaybe<Scalars['ObjectId']['input']>;
  gt?: InputMaybe<Scalars['ObjectId']['input']>;
  gte?: InputMaybe<Scalars['ObjectId']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['ObjectId']['input']>>>;
  lt?: InputMaybe<Scalars['ObjectId']['input']>;
  lte?: InputMaybe<Scalars['ObjectId']['input']>;
  neq?: InputMaybe<Scalars['ObjectId']['input']>;
  ngt?: InputMaybe<Scalars['ObjectId']['input']>;
  ngte?: InputMaybe<Scalars['ObjectId']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['ObjectId']['input']>>>;
  nlt?: InputMaybe<Scalars['ObjectId']['input']>;
  nlte?: InputMaybe<Scalars['ObjectId']['input']>;
};

export type OrderCompanyData = {
  __typename?: 'OrderCompanyData';
  billToAddress: AddressData;
  contact: OrderContactData;
  id: Scalars['String']['output'];
  location: LocationData;
  name: Scalars['String']['output'];
};

export type OrderCompanyDataInput = {
  billToAddress: AddressDataInput;
  contact: OrderContactDataInput;
  id: Scalars['String']['input'];
  location: LocationDataInput;
  name: Scalars['String']['input'];
};

export type OrderContactData = {
  __typename?: 'OrderContactData';
  emailAddress: EmailAddressData;
  firstName: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
  phoneNumber: PhoneNumberData;
  title: Scalars['String']['output'];
  type: ContactType;
};

export type OrderContactDataInput = {
  emailAddress: EmailAddressDataInput;
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  phoneNumber: PhoneNumberDataInput;
  title: Scalars['String']['input'];
  type: ContactType;
};

export type OrderPayload = {
  __typename?: 'OrderPayload';
  errors?: Maybe<Array<ValidationError>>;
  order?: Maybe<OrderStateData>;
};

export type OrderStateData = {
  __typename?: 'OrderStateData';
  company: OrderCompanyData;
  confirmationEmails: Array<ConfirmationEmailData>;
  erpId: Scalars['String']['output'];
  expectedDeliveryDate: Scalars['DateTime']['output'];
  initializationDate: Scalars['DateTime']['output'];
  lineItems: Array<LineItemData>;
  orderId: Scalars['String']['output'];
  orderNumber: Scalars['String']['output'];
  poNumber: Scalars['String']['output'];
  salesPerson: Scalars['String']['output'];
  shipFromLocation: ShipFromLocationData;
  status: OrderStatus;
  syncAttempts: Array<SyncAttemptData>;
  tenantId: Scalars['String']['output'];
  type: OrderType;
};

export enum OrderStatus {
  Cancelled = 'CANCELLED',
  Completed = 'COMPLETED',
  Deleted = 'DELETED',
  Initialized = 'INITIALIZED',
  InRoute = 'IN_ROUTE',
  Open = 'OPEN',
  PendingApproval = 'PENDING_APPROVAL',
  Submitted = 'SUBMITTED',
  Synchronized = 'SYNCHRONIZED',
  Unknown = 'UNKNOWN'
}

export type OrderStatusOperationFilterInput = {
  eq?: InputMaybe<OrderStatus>;
  in?: InputMaybe<Array<OrderStatus>>;
  neq?: InputMaybe<OrderStatus>;
  nin?: InputMaybe<Array<OrderStatus>>;
};

export type OrderSubmitterData = {
  __typename?: 'OrderSubmitterData';
  email?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
};

export type OrderSubmitterDataInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
};

export enum OrderType {
  Credit = 'CREDIT',
  Po = 'PO',
  Retail = 'RETAIL',
  Unknown = 'UNKNOWN'
}

export type OrderTypeOperationFilterInput = {
  eq?: InputMaybe<OrderType>;
  in?: InputMaybe<Array<OrderType>>;
  neq?: InputMaybe<OrderType>;
  nin?: InputMaybe<Array<OrderType>>;
};

/** A connection to a list of items. */
export type OrdersConnection = {
  __typename?: 'OrdersConnection';
  /** A list of edges. */
  edges?: Maybe<Array<OrdersEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<OrdersDocument>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

export type OrdersDocument = {
  __typename?: 'OrdersDocument';
  billingAddress: AddressData;
  companyId: Scalars['String']['output'];
  companyName: Scalars['String']['output'];
  confirmationEmail?: Maybe<ConfirmationEmailData>;
  erpId?: Maybe<Scalars['String']['output']>;
  expectedDeliveryDate: Scalars['DateTime']['output'];
  history: Array<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  initializedDate: Scalars['DateTime']['output'];
  itemIds: Scalars['String']['output'];
  locations: LocationData;
  numberOfLines: Scalars['Int']['output'];
  orderId: Scalars['String']['output'];
  orderNumber: Scalars['String']['output'];
  orderStatus: OrderStatus;
  orderTotal: MoneyData;
  orderType: OrderType;
  poNumber: Scalars['String']['output'];
  salesPerson?: Maybe<Scalars['String']['output']>;
  syncDate?: Maybe<Scalars['DateTime']['output']>;
  tenantId: Scalars['String']['output'];
};

export type OrdersDocumentFilterInput = {
  and?: InputMaybe<Array<OrdersDocumentFilterInput>>;
  billingAddress?: InputMaybe<AddressDataFilterInput>;
  companyId?: InputMaybe<StringOperationFilterInput>;
  companyName?: InputMaybe<StringOperationFilterInput>;
  confirmationEmail?: InputMaybe<ConfirmationEmailDataFilterInput>;
  erpId?: InputMaybe<StringOperationFilterInput>;
  expectedDeliveryDate?: InputMaybe<DateTimeOperationFilterInput>;
  history?: InputMaybe<ListStringOperationFilterInput>;
  id?: InputMaybe<StringOperationFilterInput>;
  initializedDate?: InputMaybe<DateTimeOperationFilterInput>;
  itemIds?: InputMaybe<StringOperationFilterInput>;
  locations?: InputMaybe<LocationDataFilterInput>;
  numberOfLines?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<OrdersDocumentFilterInput>>;
  orderId?: InputMaybe<StringOperationFilterInput>;
  orderNumber?: InputMaybe<StringOperationFilterInput>;
  orderStatus?: InputMaybe<OrderStatusOperationFilterInput>;
  orderTotal?: InputMaybe<MoneyDataFilterInput>;
  orderType?: InputMaybe<OrderTypeOperationFilterInput>;
  poNumber?: InputMaybe<StringOperationFilterInput>;
  salesPerson?: InputMaybe<StringOperationFilterInput>;
  syncDate?: InputMaybe<DateTimeOperationFilterInput>;
  tenantId?: InputMaybe<StringOperationFilterInput>;
};

export type OrdersDocumentSortInput = {
  billingAddress?: InputMaybe<AddressDataSortInput>;
  companyId?: InputMaybe<SortEnumType>;
  companyName?: InputMaybe<SortEnumType>;
  confirmationEmail?: InputMaybe<ConfirmationEmailDataSortInput>;
  erpId?: InputMaybe<SortEnumType>;
  expectedDeliveryDate?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  initializedDate?: InputMaybe<SortEnumType>;
  itemIds?: InputMaybe<SortEnumType>;
  locations?: InputMaybe<LocationDataSortInput>;
  numberOfLines?: InputMaybe<SortEnumType>;
  orderId?: InputMaybe<SortEnumType>;
  orderNumber?: InputMaybe<SortEnumType>;
  orderStatus?: InputMaybe<SortEnumType>;
  orderTotal?: InputMaybe<MoneyDataSortInput>;
  orderType?: InputMaybe<SortEnumType>;
  poNumber?: InputMaybe<SortEnumType>;
  position?: InputMaybe<SortEnumType>;
  salesPerson?: InputMaybe<SortEnumType>;
  streamPosition?: InputMaybe<SortEnumType>;
  syncDate?: InputMaybe<SortEnumType>;
  tenantId?: InputMaybe<SortEnumType>;
};

/** An edge in a connection. */
export type OrdersEdge = {
  __typename?: 'OrdersEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: OrdersDocument;
};

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']['output']>;
  /** Indicates whether more edges exist following the set defined by the clients arguments. */
  hasNextPage: Scalars['Boolean']['output'];
  /** Indicates whether more edges exist prior the set defined by the clients arguments. */
  hasPreviousPage: Scalars['Boolean']['output'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']['output']>;
};

export type PhoneNumberData = {
  __typename?: 'PhoneNumberData';
  type: PhoneNumberType;
  value: Scalars['String']['output'];
};

export type PhoneNumberDataInput = {
  type: PhoneNumberType;
  value: Scalars['String']['input'];
};

export enum PhoneNumberType {
  Fax = 'FAX',
  Home = 'HOME',
  Mobile = 'MOBILE',
  Other = 'OTHER',
  Unknown = 'UNKNOWN',
  Work = 'WORK'
}

export type PositionData = {
  __typename?: 'PositionData';
  commitPosition: Scalars['Long']['output'];
  preparePosition: Scalars['Long']['output'];
};

export type Query = {
  __typename?: 'Query';
  availabilitySession?: Maybe<AvailabilitySessionStateData>;
  availabilitySessions?: Maybe<AvailabilitySessionsConnection>;
  categories?: Maybe<CategoriesConnection>;
  commandBarOptions?: Maybe<CommandBarOptionsConnection>;
  companies?: Maybe<CompaniesConnection>;
  company?: Maybe<CompanyStateData>;
  entityEvents: Array<ResolvedEventData>;
  erpItemPrices: Array<ErpItemPricingDocument>;
  erpItemPricing: ErpItemPricingStateData;
  erpPriceGroups: Array<Maybe<Scalars['String']['output']>>;
  integrations: Array<TenantIntegrationDocument>;
  item?: Maybe<ItemStateData>;
  itemAvailability: Array<ItemAvailabilityDocument>;
  itemLocationAvailability: Array<ItemLocationAvailabilityDocument>;
  itemPrograms: Array<ItemProgramsDocument>;
  items?: Maybe<ItemsConnection>;
  locationsSalesPeople?: Maybe<LocationsSalesPeopleConnection>;
  order?: Maybe<OrderStateData>;
  orders?: Maybe<OrdersConnection>;
  shoppingCart?: Maybe<ShoppingCartStateData>;
  sizes?: Maybe<SizesConnection>;
  synchronizationIterations?: Maybe<SynchronizationIterationsConnection>;
  tenant?: Maybe<TenantStateData>;
  unitsOfMeasure?: Maybe<UnitsOfMeasureConnection>;
  version: Scalars['String']['output'];
};


export type QueryAvailabilitySessionArgs = {
  sessionId: Scalars['String']['input'];
  tenantId: Scalars['String']['input'];
};


export type QueryAvailabilitySessionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<AvailabilitySessionDocumentSortInput>>;
  where?: InputMaybe<AvailabilitySessionDocumentFilterInput>;
};


export type QueryCategoriesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<CategoryCollectionSortInput>>;
  where?: InputMaybe<CategoryCollectionFilterInput>;
};


export type QueryCommandBarOptionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<CommandBarDocumentSortInput>>;
  where?: InputMaybe<CommandBarDocumentFilterInput>;
};


export type QueryCompaniesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<CompaniesSummaryDocumentSortInput>>;
  where?: InputMaybe<CompaniesSummaryDocumentFilterInput>;
};


export type QueryCompanyArgs = {
  companyId: Scalars['String']['input'];
  tenantId: Scalars['String']['input'];
};


export type QueryEntityEventsArgs = {
  entityId?: InputMaybe<Scalars['String']['input']>;
  entityName: Scalars['String']['input'];
  tenantId: Scalars['String']['input'];
};


export type QueryErpItemPricesArgs = {
  order?: InputMaybe<Array<ErpItemPricingDocumentSortInput>>;
  where?: InputMaybe<ErpItemPricingDocumentFilterInput>;
};


export type QueryErpItemPricingArgs = {
  erpPriceGroupId: Scalars['String']['input'];
  startYear: Scalars['Int']['input'];
  tenantId: Scalars['String']['input'];
};


export type QueryErpPriceGroupsArgs = {
  tenantId: Scalars['String']['input'];
};


export type QueryIntegrationsArgs = {
  order?: InputMaybe<Array<TenantIntegrationDocumentSortInput>>;
  where?: InputMaybe<TenantIntegrationDocumentFilterInput>;
};


export type QueryItemArgs = {
  itemId: Scalars['String']['input'];
  tenantId: Scalars['String']['input'];
};


export type QueryItemAvailabilityArgs = {
  order?: InputMaybe<Array<ItemAvailabilityDocumentSortInput>>;
  where?: InputMaybe<ItemAvailabilityDocumentFilterInput>;
};


export type QueryItemLocationAvailabilityArgs = {
  order?: InputMaybe<Array<ItemLocationAvailabilityDocumentSortInput>>;
  where?: InputMaybe<ItemLocationAvailabilityDocumentFilterInput>;
};


export type QueryItemProgramsArgs = {
  order?: InputMaybe<Array<ItemProgramsDocumentSortInput>>;
  where?: InputMaybe<ItemProgramsDocumentFilterInput>;
};


export type QueryItemsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<ItemSummaryDocumentSortInput>>;
  where?: InputMaybe<ItemSummaryDocumentFilterInput>;
};


export type QueryLocationsSalesPeopleArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<LocationsSalesPersonDocumentSortInput>>;
  where?: InputMaybe<LocationsSalesPersonDocumentFilterInput>;
};


export type QueryOrderArgs = {
  orderId: Scalars['String']['input'];
  tenantId: Scalars['String']['input'];
};


export type QueryOrdersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<OrdersDocumentSortInput>>;
  where?: InputMaybe<OrdersDocumentFilterInput>;
};


export type QueryShoppingCartArgs = {
  cartId: Scalars['String']['input'];
  tenantId: Scalars['String']['input'];
};


export type QuerySizesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<SizesCollectionSortInput>>;
  where?: InputMaybe<SizesCollectionFilterInput>;
};


export type QuerySynchronizationIterationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<IterationDocumentSortInput>>;
  where?: InputMaybe<IterationDocumentFilterInput>;
};


export type QueryTenantArgs = {
  tenantId: Scalars['String']['input'];
};


export type QueryUnitsOfMeasureArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<UnitOfMeasureCollectionSortInput>>;
  where?: InputMaybe<UnitOfMeasureCollectionFilterInput>;
};

export type RecordAdjustmentInput = {
  comment: Scalars['String']['input'];
  direction: AvailabilityAdjustmentDirection;
  itemId: Scalars['String']['input'];
  quantity: Scalars['Int']['input'];
  reason: AvailabilityAdjustmentReason;
  recordedAt: Scalars['DateTime']['input'];
  recordedBy: Scalars['String']['input'];
  tenantId: Scalars['String']['input'];
};

export type RecordCountInput = {
  counts: Array<CountDataInput>;
  recordedAt: Scalars['DateTime']['input'];
  recordedBy: Scalars['String']['input'];
  tenantId: Scalars['String']['input'];
};

export type RemoveBillingAddressInput = {
  addressId: Scalars['UUID']['input'];
  companyId: Scalars['String']['input'];
  tenantId: Scalars['String']['input'];
};

export type RemoveCategoryInput = {
  code: Scalars['String']['input'];
  tenantId: Scalars['String']['input'];
};

export type RemoveContactInput = {
  companyId: Scalars['String']['input'];
  contactId: Scalars['UUID']['input'];
  tenantId: Scalars['String']['input'];
};

export type RemoveLineItemInput = {
  id: Scalars['UUID']['input'];
  lineNumber: Scalars['Int']['input'];
  orderId: Scalars['String']['input'];
  tenantId: Scalars['String']['input'];
};

export type RemoveLocationInput = {
  companyId: Scalars['String']['input'];
  locationId: Scalars['UUID']['input'];
  tenantId: Scalars['String']['input'];
};

export type RemoveSizesInput = {
  code: Scalars['String']['input'];
  tenantId: Scalars['String']['input'];
};

export type RemoveUnitInput = {
  code: Scalars['String']['input'];
  tenantId: Scalars['String']['input'];
};

export type RenameTenantInput = {
  entityId: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type ReplaceSalesPeopleInput = {
  companyId: Scalars['String']['input'];
  locationId: Scalars['UUID']['input'];
  salesPeopleEmails: Array<Scalars['String']['input']>;
  tenantId: Scalars['String']['input'];
};

export type ResolvedEventData = {
  __typename?: 'ResolvedEventData';
  event: EventRecordData;
  isResolved: Scalars['Boolean']['output'];
  link?: Maybe<EventRecordData>;
  originalEventNumber: Scalars['Long']['output'];
  originalPosition?: Maybe<PositionData>;
  originalStreamId: Scalars['String']['output'];
};

export type SalesPersonData = {
  __typename?: 'SalesPersonData';
  emailAddress: EmailAddressData;
};

export type SalesPersonDataFilterInput = {
  and?: InputMaybe<Array<SalesPersonDataFilterInput>>;
  emailAddress?: InputMaybe<EmailAddressDataFilterInput>;
  or?: InputMaybe<Array<SalesPersonDataFilterInput>>;
};

export type SalesPersonDataInput = {
  emailAddress: EmailAddressDataInput;
};

export type SalesPersonMapDataInput = {
  code: Scalars['String']['input'];
  email: Scalars['String']['input'];
};

export type ShipFromLocationData = {
  __typename?: 'ShipFromLocationData';
  name: Scalars['String']['output'];
};

export type ShipFromLocationDataInput = {
  name: Scalars['String']['input'];
};

export type ShopperData = {
  __typename?: 'ShopperData';
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
  phone: Scalars['String']['output'];
};

export type ShoppingCartCompanyData = {
  __typename?: 'ShoppingCartCompanyData';
  billToAddress: CartAddressData;
  companyId: Scalars['String']['output'];
  name: Scalars['String']['output'];
  shipToAddress: CartAddressData;
  shopper: ShopperData;
};

export type ShoppingCartItemData = {
  __typename?: 'ShoppingCartItemData';
  dateAdded: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  itemGroup: Scalars['String']['output'];
  itemId: Scalars['String']['output'];
  name: Scalars['String']['output'];
  price: Scalars['Decimal']['output'];
  quantity: Scalars['Int']['output'];
  sku: Scalars['String']['output'];
  upc: Scalars['String']['output'];
};

export type ShoppingCartPayload = {
  __typename?: 'ShoppingCartPayload';
  errors?: Maybe<Array<ValidationError>>;
  shoppingCart?: Maybe<ShoppingCartStateData>;
};

export type ShoppingCartStateData = {
  __typename?: 'ShoppingCartStateData';
  cartId: Scalars['String']['output'];
  company: ShoppingCartCompanyData;
  createdAt: Scalars['DateTime']['output'];
  expectedDeliveryDate: Scalars['DateTime']['output'];
  items: Array<ShoppingCartItemData>;
  shopperId: Scalars['String']['output'];
  status: ShoppingCartStatus;
  tenantId: Scalars['String']['output'];
};

export enum ShoppingCartStatus {
  Cancelled = 'CANCELLED',
  Confirmed = 'CONFIRMED',
  Empty = 'EMPTY',
  Open = 'OPEN',
  Submitted = 'SUBMITTED'
}

export type SizesCollection = {
  __typename?: 'SizesCollection';
  id: Scalars['String']['output'];
  sizes: SizesData;
  tenantId: Scalars['String']['output'];
};

export type SizesCollectionFilterInput = {
  and?: InputMaybe<Array<SizesCollectionFilterInput>>;
  id?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<SizesCollectionFilterInput>>;
  sizes?: InputMaybe<SizesDataFilterInput>;
  tenantId?: InputMaybe<StringOperationFilterInput>;
};

export type SizesCollectionSortInput = {
  id?: InputMaybe<SortEnumType>;
  sizes?: InputMaybe<SizesDataSortInput>;
  tenantId?: InputMaybe<SortEnumType>;
};

/** A connection to a list of items. */
export type SizesConnection = {
  __typename?: 'SizesConnection';
  /** A list of edges. */
  edges?: Maybe<Array<SizesEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<SizesCollection>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

export type SizesData = {
  __typename?: 'SizesData';
  code: Scalars['String']['output'];
  eaches: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  uom: Scalars['Int']['output'];
};

export type SizesDataFilterInput = {
  and?: InputMaybe<Array<SizesDataFilterInput>>;
  code?: InputMaybe<StringOperationFilterInput>;
  eaches?: InputMaybe<IntOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<SizesDataFilterInput>>;
  uom?: InputMaybe<IntOperationFilterInput>;
};

export type SizesDataInput = {
  code: Scalars['String']['input'];
  eaches: Scalars['Int']['input'];
  name: Scalars['String']['input'];
  uom: Scalars['Int']['input'];
};

export type SizesDataSortInput = {
  code?: InputMaybe<SortEnumType>;
  eaches?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  uom?: InputMaybe<SortEnumType>;
};

/** An edge in a connection. */
export type SizesEdge = {
  __typename?: 'SizesEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: SizesCollection;
};

export type SizesPayload = {
  __typename?: 'SizesPayload';
  data?: Maybe<SizesPayloadData>;
  errors?: Maybe<Array<ValidationError>>;
};

export type SizesPayloadData = {
  __typename?: 'SizesPayloadData';
  id: Scalars['String']['output'];
  sizes: SizesData;
  tenantId: Scalars['String']['output'];
};

export enum SortEnumType {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type StartAvailabilitySessionInput = {
  comment: Scalars['String']['input'];
  recordedAt: Scalars['DateTime']['input'];
  recordedBy: Scalars['String']['input'];
  sessionId: Scalars['String']['input'];
  tenantId: Scalars['String']['input'];
};

export type StringOperationFilterInput = {
  and?: InputMaybe<Array<StringOperationFilterInput>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  endsWith?: InputMaybe<Scalars['String']['input']>;
  eq?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  ncontains?: InputMaybe<Scalars['String']['input']>;
  nendsWith?: InputMaybe<Scalars['String']['input']>;
  neq?: InputMaybe<Scalars['String']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  nstartsWith?: InputMaybe<Scalars['String']['input']>;
  or?: InputMaybe<Array<StringOperationFilterInput>>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
};

export type SyncAttemptData = {
  __typename?: 'SyncAttemptData';
  dateAttempted: Scalars['DateTime']['output'];
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

/** A connection to a list of items. */
export type SynchronizationIterationsConnection = {
  __typename?: 'SynchronizationIterationsConnection';
  /** A list of edges. */
  edges?: Maybe<Array<SynchronizationIterationsEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<IterationDocument>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type SynchronizationIterationsEdge = {
  __typename?: 'SynchronizationIterationsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: IterationDocument;
};

export type SynchronizeErpItemPricingInput = {
  erpPriceGroupId: Scalars['String']['input'];
  itemPrices: Array<ItemPriceInput>;
  tenantId: Scalars['String']['input'];
};

export type SynchronizeSalesPeopleInput = {
  salesPeople: Array<SalesPersonMapDataInput>;
  tenantId: Scalars['String']['input'];
};

export type TenantIntegrationData = {
  __typename?: 'TenantIntegrationData';
  accessToken: Scalars['String']['output'];
  accessTokenExpiration: Scalars['DateTime']['output'];
  created: Scalars['DateTime']['output'];
  integrationId: Scalars['String']['output'];
  realmId: Scalars['String']['output'];
  refreshToken: Scalars['String']['output'];
  refreshTokenExpiration: Scalars['DateTime']['output'];
  source: IntegrationSource;
};

export type TenantIntegrationDocument = {
  __typename?: 'TenantIntegrationDocument';
  accessToken: Scalars['String']['output'];
  accessTokenExpiresAt: Scalars['DateTime']['output'];
  integrationId: Scalars['String']['output'];
  realmId?: Maybe<Scalars['String']['output']>;
  refreshToken: Scalars['String']['output'];
  refreshTokenExpiresAt: Scalars['DateTime']['output'];
  source: IntegrationSource;
  tenantId: Scalars['String']['output'];
};

export type TenantIntegrationDocumentFilterInput = {
  accessToken?: InputMaybe<StringOperationFilterInput>;
  accessTokenExpiresAt?: InputMaybe<DateTimeOperationFilterInput>;
  and?: InputMaybe<Array<TenantIntegrationDocumentFilterInput>>;
  integrationId?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<TenantIntegrationDocumentFilterInput>>;
  realmId?: InputMaybe<StringOperationFilterInput>;
  refreshToken?: InputMaybe<StringOperationFilterInput>;
  refreshTokenExpiresAt?: InputMaybe<DateTimeOperationFilterInput>;
  source?: InputMaybe<IntegrationSourceOperationFilterInput>;
  tenantId?: InputMaybe<StringOperationFilterInput>;
};

export type TenantIntegrationDocumentSortInput = {
  accessToken?: InputMaybe<SortEnumType>;
  accessTokenExpiresAt?: InputMaybe<SortEnumType>;
  integrationId?: InputMaybe<SortEnumType>;
  realmId?: InputMaybe<SortEnumType>;
  refreshToken?: InputMaybe<SortEnumType>;
  refreshTokenExpiresAt?: InputMaybe<SortEnumType>;
  source?: InputMaybe<SortEnumType>;
  tenantId?: InputMaybe<SortEnumType>;
};

export type TenantPayload = {
  __typename?: 'TenantPayload';
  errors?: Maybe<Array<ValidationError>>;
  tenant?: Maybe<TenantStateData>;
};

export type TenantStateData = {
  __typename?: 'TenantStateData';
  created: Scalars['DateTime']['output'];
  integrations: Array<TenantIntegrationData>;
  name: Scalars['String']['output'];
  status: TenantStatus;
  tenantId: Scalars['String']['output'];
};

export enum TenantStatus {
  Active = 'ACTIVE',
  Deleted = 'DELETED',
  Inactive = 'INACTIVE'
}

export type UnitOfMeasureCollection = {
  __typename?: 'UnitOfMeasureCollection';
  code: Scalars['String']['output'];
  description: Scalars['String']['output'];
  id: Scalars['String']['output'];
  tenantId: Scalars['String']['output'];
};

export type UnitOfMeasureCollectionFilterInput = {
  and?: InputMaybe<Array<UnitOfMeasureCollectionFilterInput>>;
  code?: InputMaybe<StringOperationFilterInput>;
  description?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<UnitOfMeasureCollectionFilterInput>>;
  tenantId?: InputMaybe<StringOperationFilterInput>;
};

export type UnitOfMeasureCollectionSortInput = {
  code?: InputMaybe<SortEnumType>;
  description?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  tenantId?: InputMaybe<SortEnumType>;
};

export type UnitOfMeasureData = {
  __typename?: 'UnitOfMeasureData';
  code: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type UnitOfMeasurePayload = {
  __typename?: 'UnitOfMeasurePayload';
  errors?: Maybe<Array<ValidationError>>;
  unitOfMeasure?: Maybe<UnitOfMeasureCollection>;
};

/** A connection to a list of items. */
export type UnitsOfMeasureConnection = {
  __typename?: 'UnitsOfMeasureConnection';
  /** A list of edges. */
  edges?: Maybe<Array<UnitsOfMeasureEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<UnitOfMeasureCollection>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type UnitsOfMeasureEdge = {
  __typename?: 'UnitsOfMeasureEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: UnitOfMeasureCollection;
};

export type UpdateAndGetOrderNumberInput = {
  tenantId: Scalars['String']['input'];
};

export type UpdateBillingAddressInput = {
  companyId: Scalars['String']['input'];
  newAddress: AddressDataInput;
  tenantId: Scalars['String']['input'];
};

export type UpdateCartInput = {
  billToAddressCity: Scalars['String']['input'];
  billToAddressCountry: Scalars['String']['input'];
  billToAddressId: Scalars['String']['input'];
  billToAddressLine1: Scalars['String']['input'];
  billToAddressLine2: Scalars['String']['input'];
  billToAddressStateProvince: Scalars['String']['input'];
  billToAddressToName: Scalars['String']['input'];
  billToAddressZipcode: Scalars['String']['input'];
  cartId: Scalars['String']['input'];
  companyId: Scalars['String']['input'];
  companyName: Scalars['String']['input'];
  expectedDeliveryDate: Scalars['DateTime']['input'];
  shipToAddressCity: Scalars['String']['input'];
  shipToAddressCountry: Scalars['String']['input'];
  shipToAddressId: Scalars['String']['input'];
  shipToAddressLine1: Scalars['String']['input'];
  shipToAddressLine2: Scalars['String']['input'];
  shipToAddressStateProvince: Scalars['String']['input'];
  shipToAddressToName: Scalars['String']['input'];
  shipToAddressZipcode: Scalars['String']['input'];
  shopperEmail: Scalars['String']['input'];
  shopperFirstName: Scalars['String']['input'];
  shopperId: Scalars['String']['input'];
  shopperLastName: Scalars['String']['input'];
  shopperPhone: Scalars['String']['input'];
  tenantId: Scalars['String']['input'];
};

export type UpdateCategoryInput = {
  category: CategoryDataInput;
  tenantId: Scalars['String']['input'];
};

export type UpdateConfirmationEmailInput = {
  emailAddress: Scalars['String']['input'];
  orderId: Scalars['String']['input'];
  tenantId: Scalars['String']['input'];
};

export type UpdateContactInput = {
  companyId: Scalars['String']['input'];
  contact: ContactDataInput;
  tenantId: Scalars['String']['input'];
};

export type UpdateCountInput = {
  comment: Scalars['String']['input'];
  countRowId: Scalars['String']['input'];
  grade: Scalars['Int']['input'];
  quantity: Scalars['Int']['input'];
  recordedAt: Scalars['DateTime']['input'];
  recordedBy: Scalars['String']['input'];
  sessionId: Scalars['String']['input'];
  tenantId: Scalars['String']['input'];
};

export type UpdateDefaultOrderSubmitterInput = {
  companyId: Scalars['String']['input'];
  orderSubmitter: OrderSubmitterDataInput;
  tenantId: Scalars['String']['input'];
};

export type UpdateImageUrlInput = {
  imageUrl: Scalars['String']['input'];
  itemId: Scalars['String']['input'];
  tenantId: Scalars['String']['input'];
};

export type UpdateItemInput = {
  categories: Array<CategoryDataInput>;
  description: Scalars['String']['input'];
  erpId?: Scalars['String']['input'];
  itemGroup: Scalars['String']['input'];
  itemId: Scalars['String']['input'];
  name: Scalars['String']['input'];
  programs?: InputMaybe<Array<Scalars['String']['input']>>;
  sellPrice?: InputMaybe<Scalars['Decimal']['input']>;
  sku: Scalars['String']['input'];
  tenantId: Scalars['String']['input'];
  upc: Scalars['String']['input'];
};

export type UpdateLineItemInput = {
  lineItem: LineItemUpdateDataInput;
  lineNumber: Scalars['Int']['input'];
  orderId: Scalars['String']['input'];
  tenantId: Scalars['String']['input'];
};

export type UpdateLocationInput = {
  companyId: Scalars['String']['input'];
  location: LocationDataInput;
  tenantId: Scalars['String']['input'];
};

export type UpdateMissingItemGroupsInput = {
  orderId: Scalars['String']['input'];
  tenantId: Scalars['String']['input'];
};

export type UpdateOrderContactInput = {
  contact: OrderContactDataInput;
  orderId: Scalars['String']['input'];
  tenantId: Scalars['String']['input'];
};

export type UpdateOrderStatusInput = {
  orderId: Scalars['String']['input'];
  status: OrderStatus;
  tenantId: Scalars['String']['input'];
};

export type UpdateSalesPersonInput = {
  emailAddress: Scalars['String']['input'];
  orderId: Scalars['String']['input'];
  tenantId: Scalars['String']['input'];
};

export type UpdateSellPriceInput = {
  itemId: Scalars['String']['input'];
  sellPrice: Scalars['Decimal']['input'];
  tenantId: Scalars['String']['input'];
};

export type UpdateSizesInput = {
  sizes: SizesDataInput;
  tenantId: Scalars['String']['input'];
};

export type UpdateSyncAttemptInput = {
  attemptDate: Scalars['DateTime']['input'];
  message: Scalars['String']['input'];
  orderId: Scalars['String']['input'];
  success: Scalars['Boolean']['input'];
  tenantId: Scalars['String']['input'];
};

export type UpdateUnitInput = {
  code: Scalars['String']['input'];
  description: Scalars['String']['input'];
  tenantId: Scalars['String']['input'];
};

export type UpsertTenantIntegrationInput = {
  accessToken: Scalars['String']['input'];
  accessTokenExpiration: Scalars['DateTime']['input'];
  entityId: Scalars['String']['input'];
  integrationId: Scalars['String']['input'];
  realmId: Scalars['String']['input'];
  refreshToken: Scalars['String']['input'];
  refreshTokenExpiration: Scalars['DateTime']['input'];
  source: IntegrationSource;
};

export type UuidOperationFilterInput = {
  eq?: InputMaybe<Scalars['UUID']['input']>;
  gt?: InputMaybe<Scalars['UUID']['input']>;
  gte?: InputMaybe<Scalars['UUID']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['UUID']['input']>>>;
  lt?: InputMaybe<Scalars['UUID']['input']>;
  lte?: InputMaybe<Scalars['UUID']['input']>;
  neq?: InputMaybe<Scalars['UUID']['input']>;
  ngt?: InputMaybe<Scalars['UUID']['input']>;
  ngte?: InputMaybe<Scalars['UUID']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['UUID']['input']>>>;
  nlt?: InputMaybe<Scalars['UUID']['input']>;
  nlte?: InputMaybe<Scalars['UUID']['input']>;
};

export type ValidationError = {
  __typename?: 'ValidationError';
  message: Scalars['String']['output'];
};

export type ItemsQueryVariables = Exact<{
  tenantId: Scalars['String']['input'];
  first: Scalars['Int']['input'];
  after?: InputMaybe<Scalars['String']['input']>;
}>;


export type ItemsQuery = { __typename?: 'Query', items?: { __typename?: 'ItemsConnection', totalCount: number, pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null, endCursor?: string | null }, nodes?: Array<{ __typename?: 'ItemSummaryDocument', description: string, erpId: string, group: string, history: Array<string>, id: string, itemId: string, name: string, sellPrice?: any | null, sku: string, status: ItemStatus, tenantId: string, upc: string }> | null } | null };

export type RecordAvailabilitySessionCountsMutationVariables = Exact<{
  input: RecordCountInput;
}>;


export type RecordAvailabilitySessionCountsMutation = { __typename?: 'Mutation', recordAvailabilitySessionCounts: { __typename?: 'AvailabilitySessionPayload', errors?: Array<{ __typename?: 'ValidationError', message: string }> | null, session?: { __typename?: 'AvailabilitySessionStateData', sessionId: string, status: AvailabilitySessionStatus, tenantId: string, adjustments: Array<{ __typename?: 'AvailabilityAdjustmentData', comment: string, direction: AvailabilityAdjustmentDirection, itemId: string, itemName: string, quantity: number, reason: AvailabilityAdjustmentReason, recordedAt: any, recordedBy: string, sku: string, upc: string }>, counts: Array<{ __typename?: 'AvailabilityCountData', comment: string, countRowId: string, grade: number, itemId: string, itemName: string, locationId: string, quantity: number, recordedAt: any, recordedBy: string, sku: string, upc: string }>, logs: Array<{ __typename?: 'AvailabilitySessionLogData', comment: string, recordedAt: any, recordedBy: string }> } | null } };


export const ItemsDocument = gql`
    query items($tenantId: String!, $first: Int!, $after: String) {
  items(where: {tenantId: {eq: $tenantId}}, first: $first, after: $after) {
    totalCount
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    nodes {
      description
      erpId
      group
      history
      id
      itemId
      name
      sellPrice
      sku
      status
      tenantId
      upc
    }
  }
}
    `;
export const RecordAvailabilitySessionCountsDocument = gql`
    mutation recordAvailabilitySessionCounts($input: RecordCountInput!) {
  recordAvailabilitySessionCounts(input: $input) {
    errors {
      message
    }
    session {
      sessionId
      status
      tenantId
      adjustments {
        comment
        direction
        itemId
        itemName
        quantity
        reason
        recordedAt
        recordedBy
        sku
        upc
      }
      counts {
        comment
        countRowId
        grade
        itemId
        itemName
        locationId
        quantity
        recordedAt
        recordedBy
        sku
        upc
      }
      logs {
        comment
        recordedAt
        recordedBy
      }
    }
  }
}
    `;

export type SdkFunctionWrapper = <T>(action: (requestHeaders?:Record<string, string>) => Promise<T>, operationName: string, operationType?: string, variables?: any) => Promise<T>;


const defaultWrapper: SdkFunctionWrapper = (action, _operationName, _operationType, _variables) => action();

export function getSdk(client: GraphQLClient, withWrapper: SdkFunctionWrapper = defaultWrapper) {
  return {
    items(variables: ItemsQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<ItemsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<ItemsQuery>(ItemsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'items', 'query', variables);
    },
    recordAvailabilitySessionCounts(variables: RecordAvailabilitySessionCountsMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<RecordAvailabilitySessionCountsMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<RecordAvailabilitySessionCountsMutation>(RecordAvailabilitySessionCountsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'recordAvailabilitySessionCounts', 'mutation', variables);
    }
  };
}
export type Sdk = ReturnType<typeof getSdk>;