import { useEffect, useState, useContext } from "react";

//material-ui
import { Button, Card, CardContent, CardActions, Typography } from "@mui/material";

//dexie
import db from "../db";

//local
import ModalDialog from "../components/ModalDialog";
import { TranslationContext } from "../providers/TranslationProvider";
import SpacedGridItem from "../components/SpacedGridItem";
import SpacedGridContainer from "../components/SpacedGridContainer";

const ErrorLog = (props: any) => {
	const pageTitle = "Error Log";

	const pageButtons: any = [{}];
	const { getTranslation } = useContext(TranslationContext);
	const [errors, setErrors] = useState<any>([]);
	const [showingModal, setShowingModal] = useState(-1);

	const pageCallbacks = async () => {
		props.setTheme("main");
		props.setPageTitle(pageTitle);
		props.setPageButtons(pageButtons);
	};

	const getErrors = async () => {
		await db.errorLog.reverse().toArray((data) => {
			setErrors(data);
		});
	};

	useEffect(() => {
		pageCallbacks();
		getErrors();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if (!errors.length) {
		return <SpacedGridItem>{getTranslation("info-nothing-to-show")}</SpacedGridItem>;
	}

	return (
		<SpacedGridContainer>
			{errors.map((v: any, i: number) => {
				return (
					<SpacedGridItem key={i} smMargin>
						<Card style={{ width: "100%", height: "100%	" }}>
							<CardContent>
								<Typography gutterBottom>{v.timestamp}</Typography>
								<Typography variant="h6" component="div">
									{v.type}
								</Typography>
								<Typography variant="body2">{v.message}</Typography>
							</CardContent>
							<CardActions>
								<Button size="small" color="secondary" variant="contained" onClick={() => setShowingModal(v.id)}>
									{getTranslation("action-show-details")}
								</Button>
							</CardActions>
						</Card>

						<ModalDialog
							open={showingModal === v.id}
							close={() => {
								setShowingModal(-1);
							}}
							title={getTranslation("error-error-details")}
							showCloseButton
						>
							<pre style={{ whiteSpace: "pre-wrap" }}>
								{getTranslation("error-error-details")}:
								<Typography paragraph variant="body2">
									{String(v.detail) === "[object Object]" ? JSON.stringify(v.detail, null, 1) : String(v.detail)}
								</Typography>
								{getTranslation("general-data")}:
								<Typography paragraph variant="body2">
									{String(v.data) === "[object Object]" ? JSON.stringify(v.data, null, 1) : String(v.data)}
								</Typography>
							</pre>
						</ModalDialog>
					</SpacedGridItem>
				);
			})}
		</SpacedGridContainer>
	);
};
export default ErrorLog;
