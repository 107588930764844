import { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { produce } from "immer";

//material-ui
import { Alert, Box, Button, Chip, Grid, IconButton, Typography } from "@mui/material";
import { useConfirm } from "material-ui-confirm";
import TrashIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";

//dexie
import db from "../db";

//graphql
import { getSdk, RecordCountInput, CountDataInput } from "../gql/graphql";

//providers
import { useSnacks } from "../providers/SnackProvider";
import { GraphQLContext } from "../providers/GraphqlProvider";
import { AuthContext } from "../auth/AuthProvider";

//helpers
import { pruneCounts } from "../helpers/pruneCounts";

//components
import CountBox from "../components/CountBox";
import CountDetail from "../components/CountDetail";
import SpacedGridContainer from "../components/SpacedGridContainer";
import SpacedGridItem from "../components/SpacedGridItem";

const Post = () => {
	const [counts, setCounts] = useState<AppItemCount[]>([]);
	const [countsLength, setCountsLength] = useState(-1);
	const [posted, setPosted] = useState(0);
	const [errors, setErrors] = useState<string[]>([]);
	const [showErrors, setShowErrors] = useState(false);

	const confirm = useConfirm();
	const [openSnack] = useSnacks();
	const { graphClient } = useContext(GraphQLContext);
	const authContext = useContext(AuthContext);
	const sdk = getSdk(graphClient);

	const updateCounts = () => {
		db.itemCounts.toArray((data) => {
			let filteredData = data.filter((ic) => ic.status !== "posted");
			setCounts(filteredData);
			setCountsLength(filteredData.length);
		});
	};

	const handleDelete = (id: number) => {
		confirm({
			title: "Remove this count?",
			cancellationButtonProps: { variant: "outlined" },
			confirmationButtonProps: { variant: "contained", color: "primary" },
		})
			.then(() => {
				return db.itemCounts.delete(id);
			})
			.then(() => {
				updateCounts();
			})
			.catch((e: Error) => {
				console.log("Error handling count deletion: ", e);
			});
	};

	const handlePost = () => {
		if (!navigator.onLine) {
			openSnack("Network Unavailable", "warn");
			return;
		}
		confirm({
			title: "Post Counts?",
			cancellationButtonProps: { variant: "outlined" },
			confirmationButtonProps: { variant: "contained", color: "primary" },
		})
			.then(() => {
				if (counts.length) {
					setPosted(1);
					postCounts();
				} else {
					openSnack("No Counts Available to Post", "warn");
				}
			})
			.catch(() => {});
	};

	const postCounts = async () => {
		let tenantId = authContext.company?.abbreviation || "unknown";
		let recordedBy = authContext.currentUser?.email || "unknown";
		let recordedAt = new Date().toISOString();
		let postCounts: CountDataInput[] = counts.map((c) => c.itemCount);
		let input: RecordCountInput = { counts: postCounts, tenantId: tenantId, recordedBy: recordedBy, recordedAt: recordedAt };
		let res = await sdk.recordAvailabilitySessionCounts({ input: input });

		if (res.recordAvailabilitySessionCounts.errors) {
			setErrors(
				produce((draft) => {
					return [...draft, res.recordAvailabilitySessionCounts.errors?.map((e) => e.message)];
				})
			);
			setPosted(0);
			return;
		}
		res.recordAvailabilitySessionCounts.session?.counts.forEach(async (r) => {
			await db.itemCounts
				.where({ itemId: r.itemId })
				.modify({
					status: "posted",
				})
				.then(() => {
					return null;
				})
				.catch((err) => {
					console.error("postCount retail update error", err);
					setErrors(
						produce((draft) => {
							return [...draft, String(err)];
						})
					);
				});
		});
		updateCounts();
		setPosted(2);
	};

	useEffect(() => {
		pruneCounts();
		updateCounts();
	}, []);

	return (
		<SpacedGridContainer>
			{countsLength < 1 ? (
				<SpacedGridItem maxCols={1} smMargin={true}>
					{countsLength < 0 ? (
						<Alert severity="info">Loading...</Alert>
					) : (
						<>{posted ? <Alert severity="success">Counts posted</Alert> : <Alert severity="info">No counts available to post</Alert>}</>
					)}
				</SpacedGridItem>
			) : (
				<>
					<SpacedGridItem maxCols={1} smMargin={true}>
						<Button variant="contained" color="primary" onClick={() => handlePost()}>
							Post Counts
						</Button>
						{posted == 1 ? <Chip style={{ marginLeft: "1em" }} label={`Posting ${countsLength} counts`} /> : null}
						{errors.length > 0 ? (
							<>
								<Alert
									severity="error"
									action={
										<Button variant="contained" color="inherit" size="small" onClick={() => setShowErrors(!showErrors)}>
											{showErrors ? "Hide" : "Show"}
										</Button>
									}
								>
									{errors.length} Error Posting Counts
								</Alert>
								{showErrors ? (
									<Box>
										<ol>
											{errors.map((err, _i) => (
												<li>{`${String(err)}`}</li>
											))}
										</ol>
									</Box>
								) : null}
							</>
						) : null}
					</SpacedGridItem>

					{counts.length &&
						counts.map((count, i) => (
							<SpacedGridItem maxCols={3} key={i}>
								<CountBox key={count.id || i} error={false}>
									<Grid item xs>
										<Typography variant="h6">Count</Typography>
									</Grid>
									<Grid item xs={4} style={{ textAlign: "end" }}>
										<IconButton
											style={{ marginRight: 10 }}
											color="secondary"
											size="small"
											onClick={() => {
												handleDelete(count.id || 0);
											}}
										>
											<TrashIcon />
										</IconButton>
										<Link to="/" state={{ itemCountId: count.id, locationId: count.itemCount.locationId }}>
											<IconButton color="secondary" size="small">
												<EditIcon />
											</IconButton>
										</Link>
									</Grid>

									<CountDetail count={count}></CountDetail>
								</CountBox>
							</SpacedGridItem>
						))}
				</>
			)}
		</SpacedGridContainer>
	);
};

export default Post;
