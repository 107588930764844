import { useState, useEffect } from "react";

//material-ui
import { Alert, Typography } from "@mui/material";
import SpacedGridContainer from "../components/SpacedGridContainer";
import CountDetail from "../components/CountDetail";

//dexie
import db from "../db";
import SelectNative from "../components/SelectNative";
import SpacedGridItem from "../components/SpacedGridItem";
import CountBox from "../components/CountBox";
import { daysToKeep } from "../helpers/pruneCounts";
const Done = () => {
	const [dates, setDates] = useState<string[]>([]);
	const [currentDate, setCurrentDate] = useState<string>("");
	const [loading, setLoading] = useState(true);
	const [itemCounts, setItemCounts] = useState<AppItemCount[]>([]);

	const getPostedDates = () => {
		db.itemCounts
			.filter((c) => c.status === "posted")
			.toArray((data) => {
				let sorted = data.sort((a, b) => (a.itemCount.recordedAt < b.itemCount.recordedAt ? 1 : -1));
				let uniqueDates = sorted.map((count) => shortDate(count.itemCount.recordedAt)).filter((v, i, a) => a.indexOf(v) === i);
				setDates(uniqueDates);
				setCurrentDate(uniqueDates[0]);
				setLoading(false);
				//getLocs(sorted, uniqueDates[0]);
			});
	};

	const getItemCounts = (date: string) => {
		db.itemCounts
			.filter((c) => c.status === "posted" && shortDate(c.itemCount.recordedAt) === date)
			.toArray((ic) => {
				setItemCounts(ic);
			});
	};

	const shortDate = (ld: string) => {
		return new Date(ld).toLocaleString("en-US", {
			year: "numeric",
			month: "numeric",
			day: "numeric",
		});
	};

	useEffect(() => {
		getPostedDates();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (currentDate === "") {
			setItemCounts([]);
		} else {
			getItemCounts(currentDate);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentDate]);

	if (loading) {
		return (
			<SpacedGridItem maxCols={1} smMargin={true}>
				<Alert severity="info">Loading...</Alert>
			</SpacedGridItem>
		);
	}

	if (dates.length < 1) {
		return (
			<SpacedGridItem maxCols={1} smMargin={true}>
				<Alert severity="info">No Counts Posted in the last {String(daysToKeep)} days</Alert>
			</SpacedGridItem>
		);
	}

	return (
		<>
			<SpacedGridItem maxCols={2} smMargin={true}>
				<Typography variant="h6">Counts Posted in the last {String(daysToKeep)} days</Typography>
			</SpacedGridItem>
			<SpacedGridItem maxCols={2} smMargin={true}>
				<SelectNative
					id="date-type-select"
					value={currentDate}
					label="Date Recorded"
					onChange={(e: any) => setCurrentDate(e.target.value)}
					options={dates.map((d, _i) => {
						return {
							value: d,
							label: d,
						};
					})}
				/>
			</SpacedGridItem>

			<SpacedGridContainer padding={{ xs: 0, md: 1 }}>
				{itemCounts.map((count, i) => (
					<CountBox key={count.id || i} error={false}>
						<CountDetail key={i} count={count}></CountDetail>
					</CountBox>
				))}
			</SpacedGridContainer>
		</>
	);
};

export default Done;
