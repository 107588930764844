import Dexie from "dexie";
import config from "./config";
import { ItemSummaryDocument } from "./gql/graphql";

class AppDatabase extends Dexie {
	public settings: Dexie.Table<AppSetting, string>;
	public errorLog: Dexie.Table<AppErrorLog, number>;
	public translations: Dexie.Table<AppTranslation, string>;
	public locations: Dexie.Table<AppLocation, string>;
	public items: Dexie.Table<ItemSummaryDocument, string>;
	public itemCounts: Dexie.Table<AppItemCount, number>;
	public constructor() {
		super("AvailabilityApp");
		//Do not remove/change primary keys unless deleting app db on start
		this.version(config.dexieVersion).stores({
			settings: "",
			errorLog: "++id",
			translations: "[key+language],language",
			locations: "locationId,parentLocationId",
			items: "itemId,upc,sku,status",
			itemCounts: "++id,itemId,status",
		});
		this.settings = this.table("settings");
		this.errorLog = this.table("errorLog");
		this.translations = this.table("translations");
		this.locations = this.table("locations");
		this.items = this.table("items");
		this.itemCounts = this.table("itemCounts");
	}
}

const db = new AppDatabase();

db.settings.toArray().catch("VersionError", (e) => {
	// Failed with VersionError
	console.error("Version error: " + e.message);
});

export default db;
