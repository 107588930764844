import { TextField } from "@mui/material";

interface CustomSelectNativeProps {
	options: { value: string | number; label: string }[];
	children?: React.ReactNode;
}
const SelectNative: React.FC<CustomSelectNativeProps & React.ComponentProps<typeof TextField>> = ({ options, children, ...otherProps }) => {
	return (
		<TextField
			select
			fullWidth={true}
			SelectProps={{
				native: true,
			}}
			variant="standard"
			{...otherProps}
		>
			{" "}
			<option value=""></option>
			{options.map((option) => (
				<option key={option.value} value={option.value}>
					{option.label}
				</option>
			))}
		</TextField>
	);
};

export default SelectNative;
