//material-ui
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import PlusIcon from "@mui/icons-material/Add";
import MinusIcon from "@mui/icons-material/Remove";
import TimesIcon from "@mui/icons-material/Clear";
import BackspaceIcon from "@mui/icons-material/BackspaceOutlined";

interface InputButtonsProps {
	parentheses?: boolean;
	onInput: (e: any, text: any) => void;
	onCalcRequest: () => void;
}

const InputButtons = (props: InputButtonsProps) => {
	const { parentheses, onInput, onCalcRequest } = props;

	interface InputButtonsProps {
		label: string | JSX.Element;
		text: string;
		icon?: string;
		span?: number;
		primary?: boolean;
	}

	const buttons: InputButtonsProps[] = [
		{ label: "1", text: "1" },
		{ label: "2", text: "2" },
		{ label: "3", text: "3" },
		{
			label: (
				<>
					&nbsp;
					<PlusIcon fontSize="small" />
					&nbsp;
				</>
			),
			text: "+",
		},
		{ label: "4", text: "4" },
		{ label: "5", text: "5" },
		{ label: "6", text: "6" },
		{
			label: (
				<>
					&nbsp;
					<MinusIcon fontSize="small" />
					&nbsp;
				</>
			),
			text: "-",
		},
		{ label: "7", text: "7" },
		{ label: "8", text: "8" },
		{ label: "9", text: "9" },
		{
			label: (
				<>
					&nbsp;
					<TimesIcon fontSize="small" />
					&nbsp;
				</>
			),
			text: "*",
		},
		{ label: ".", text: "." },
		{ label: "0", text: "0" },
		{ label: "%", text: "%" },
		{ label: "/", text: "/" },
	];

	if (parentheses) {
		buttons.push({ label: "=", text: "=", primary: true });
		buttons.push({ label: "(", text: "(" });
		buttons.push({ label: ")", text: ")" });
	} else {
		buttons.push({ label: "=", text: "=", span: 9, primary: true });
	}

	buttons.push({
		label: (
			<>
				&nbsp;
				<BackspaceIcon fontSize="small" />
				&nbsp;
			</>
		),
		text: "del",
	});

	const buttonStyle = { display: "flex", width: "100%" };

	const controls = buttons.map((button, i) => {
		const style = Object.assign({}, buttonStyle);
		const label = typeof button === "string" ? button : button.label;
		const color = button.primary === true ? "primary" : "inherit";
		const span = button.span || 3;
		const text = button.text === undefined ? label : button.text;

		const onClick = text !== "=" ? (e: any) => onInput(e, text) : onCalcRequest;

		return (
			<Grid item xs={span} key={i}>
				<Button variant="contained" className="input-button" style={style} color={color} onClick={onClick} sx={{ fontSize: "1.1em" }}>
					{label}
				</Button>
			</Grid>
		);
	});

	return <>{controls}</>;
};

export default InputButtons;
