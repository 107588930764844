import { useEffect, useState } from "react";
import SelectNative from "./SelectNative";
import { Button, Grid, Typography } from "@mui/material";
import EditLocationIcon from "@mui/icons-material/EditLocationOutlined";
import SpacedGridItem from "./SpacedGridItem";
import locationTreeToString from "../helpers/locationTreeToString";
import { getLocation, saveLocation } from "../helpers/persistLocation";

interface LocationMenuProps {
	items: AppLocation[];
	selectItem?: string;
	onLeafItemSelected: (locationId: string) => void;
}

const LocationMenu = ({ items, selectItem, onLeafItemSelected }: LocationMenuProps) => {
	const [selectedItems, setSelectedItems] = useState<AppLocation[]>([]);
	const [leafSelected, setLeafSelected] = useState<AppLocation | null>(null);

	const handleChange = (value: string, index: number) => {
		console.log("handleChange", value, index, selectedItems.slice(0, index));
		//if value is falsy than just clear out the selected items
		if (!value) {
			setSelectedItems([]);
			return;
		}
		// Update the selected items
		// If the user goes back to a previous level, remove all the selected items after that level
		const newSelectedItems = [...selectedItems.slice(0, index)];
		// Add the new selected item
		newSelectedItems[index] = items.find((item) => item.value === value) as AppLocation;
		// Update the state
		setSelectedItems(newSelectedItems);
		// Persist the selected items
		if (!selectItem) {
			saveLocation(newSelectedItems);
		}
	};

	const renderSelect = (parentItem: AppLocation | null, level: number) => {
		// if the parent item is null, it means it's the first select
		let filterValue: string | number | null = null;
		if (parentItem) {
			filterValue = parentItem.value;
		}
		const filteredItems = items.filter((item) => item.parentValue === filterValue);
		if (filteredItems.length === 0) {
			return null;
		}

		return (
			<SelectNative
				label={filteredItems[0].levelLabel || ""}
				value={selectedItems[level]?.value || ""}
				options={filteredItems}
				onChange={(event) => handleChange(event.target.value, level)}
			/>
		);
	};

	const restoreLocation = () => {
		console.log("restore location from localstorage");
		// Restore the selected items from local storage
		const storedSelectedItems = getLocation();
		if (storedSelectedItems) {
			setSelectedItems(JSON.parse(storedSelectedItems));
		}
	};

	// Function to find all parent items of a given leaf item
	const getTreeFromLeaf = (leafItemValue: string) => {
		const leafItem = items.find((item) => item.value === leafItemValue);
		if (!leafItem) return []; // Leaf item not found
		console.log("leafItem", leafItem);

		const parentItems: AppLocation[] = [];

		// Recursive function to traverse up the tree
		function findParent(itemValue: string | null) {
			const parentItem = items.find((item) => item.value === itemValue);
			if (parentItem) {
				parentItems.unshift(parentItem); // Add parent item to the beginning of the array
				findParent(parentItem.parentValue); // Recursively find parent of parent
			}
		}

		findParent(leafItem.parentValue);
		console.log("parentItems", parentItems);
		parentItems.push(leafItem);
		return parentItems;
	};

	useEffect(() => {
		onLeafItemSelected(leafSelected?.value || "");
	}, [leafSelected]);

	useEffect(() => {
		// Check if the last selected item is a leaf
		console.log("selected items", selectedItems);
		const lastSelected = selectedItems[selectedItems.length - 1];
		if (lastSelected) {
			const leafs = items.find((item) => item.parentValue === lastSelected.value);
			setLeafSelected(leafs ? null : lastSelected);
		} else {
			setLeafSelected(null);
		}
	}, [selectedItems, items]);

	useEffect(() => {
		if (selectItem) {
			setSelectedItems(getTreeFromLeaf(selectItem));
		} else {
			restoreLocation();
		}
	}, []);

	if (leafSelected !== null) {
		var itemTreeString = locationTreeToString(selectItem ? selectedItems : null);
		return (
			<SpacedGridItem maxCols={1} smMargin={true}>
				<Grid container spacing={0} alignItems="baseline" direction="row" justifyContent="center">
					<Grid
						item
						xs
						style={{
							whiteSpace: "nowrap",
							overflowY: "auto",
						}}
					>
						<Typography variant="body1">{itemTreeString}</Typography>
					</Grid>

					<Grid item style={{ textAlign: "end" }}>
						<Button color="primary" variant="contained" onClick={() => setSelectedItems(selectedItems.slice(0, selectedItems.length - 1))}>
							<EditLocationIcon />
						</Button>
					</Grid>
				</Grid>
			</SpacedGridItem>
		);
	}

	return (
		<>
			<SpacedGridItem maxCols={1} smMargin={true}>
				{renderSelect(null, 0)}
			</SpacedGridItem>
			{selectedItems.map((selectedItem, index) => (
				<SpacedGridItem key={index} maxCols={1} smMargin={true}>
					{renderSelect(selectedItem, index + 1)}
				</SpacedGridItem>
			))}
		</>
	);
};

export default LocationMenu;
