const locationTreeToString = (currentTree?: AppLocation[] | null) => {
	let tree: AppLocation[] = currentTree || JSON.parse(localStorage.getItem("locationSelected") || "[]");
	let locationTreeString = tree
		.map(function (treeItem) {
			return treeItem.label;
		})
		.join(" > ");
	return locationTreeString;
};

export default locationTreeToString;
