import { Link, useLocation } from "react-router-dom";
//material-ui
import { Badge, IconButton } from "@mui/material";
import ClipboardIcon from "@mui/icons-material/ListAlt";
import CheckCircleOutLined from "@mui/icons-material/CheckCircleOutlined";
import SyncIcon from "@mui/icons-material/Sync";
import PlusIcon from "@mui/icons-material/Add";

//dexie
import { useLiveQuery } from "dexie-react-hooks";
import db from "../db";

function PageButtons() {
	const counts = useLiveQuery(() => db.itemCounts.filter((cnt) => cnt.status !== "posted").count());

	const { pathname } = useLocation();

	return (
		<div style={{ marginLeft: "auto", display: "flex", flexDirection: "row-reverse" }}>
			<Link
				style={{
					textDecoration: "none",
					width: "auto",
					minWidth: "25%",
					display: pathname === "/post" || pathname === "/done" ? "flex" : "none",
					color: "#FFF",
				}}
				to={"/"}
				title="Count"
			>
				<IconButton color="inherit" sx={{ paddingLeft: { xs: "8px", sm: "16px" }, paddingRight: { xs: "-4px", sm: "0px" } }}>
					<PlusIcon fontSize="large" />
				</IconButton>
			</Link>
			<Link
				style={{
					textDecoration: "none",
					width: "auto",
					minWidth: "25%",
					display: pathname === "/" || pathname === "/done" ? "flex" : "none",
					color: "#FFF",
				}}
				to={"/post"}
				title="Post Data"
			>
				<IconButton color="inherit" sx={{ padding: { xs: "8px", sm: "16px" } }}>
					<Badge badgeContent={counts} color="secondary">
						<ClipboardIcon fontSize="large" />
					</Badge>
				</IconButton>
			</Link>
			<Link
				style={{
					textDecoration: "none",
					width: "auto",
					minWidth: "25%",
					display: pathname === "/post" ? "flex" : "none",
					color: "#FFF",
				}}
				to={"/done"}
				title="Posted"
			>
				<IconButton color="inherit" sx={{ padding: { xs: "8px", sm: "16px" } }}>
					<Badge color="secondary">
						<CheckCircleOutLined fontSize="large" />
					</Badge>
				</IconButton>
			</Link>
			<Link
				style={{
					textDecoration: "none",
					width: "auto",
					minWidth: "25%",
					display: pathname === "/" ? "flex" : "none",
					color: "#FFF",
				}}
				to={"/sync"}
				title="Sync Data"
			>
				<IconButton color="inherit" sx={{ padding: { xs: "8px", sm: "16px" } }}>
					<SyncIcon fontSize="large" />
				</IconButton>
			</Link>
		</div>
	);
}

export default PageButtons;
