import ReactDOM from "react-dom/client";
import App from "./App.tsx";
import AuthProvider from "./auth/AuthProvider";

ReactDOM.createRoot(document.getElementById("root")!).render(
	//<React.StrictMode>
	<AuthProvider>
		<App />
	</AuthProvider>
	//</React.StrictMode>
);
