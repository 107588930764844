//material-ui
import { Grid, GridProps } from "@mui/material";

const SpacedGridContainer: React.FC<GridProps & React.ComponentProps<typeof Grid>> = ({ children, ...props }) => {
	return (
		<Grid container mb={1} spacing={0} alignItems="baseline" direction="row" rowSpacing={{ xs: 0, md: 2 }} columnSpacing={{ xs: 0, md: 2 }} {...props}>
			{children}
		</Grid>
	);
};

export default SpacedGridContainer;
