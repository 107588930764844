import { useState } from "react";
//material-ui
import Container from "@mui/material/Container";

//local
import TopBar from "./TopBar";
import Router from "../router";
import NavDrawer from "./NavDrawer";

const Layout = () => {
	const [drawerOpen, setDrawerOpen] = useState(false);

	return (
		<>
			<TopBar setDrawerOpen={setDrawerOpen}>
				<NavDrawer drawerOpen={drawerOpen} setDrawerOpen={setDrawerOpen} />
			</TopBar>

			<Container
				maxWidth="xl"
				sx={{ padding: { xs: 0, md: 1 }, display: "flex", flexDirection: "column", justifyContent: "flex-start", flexGrow: 1, width: "100%" }}
			>
				<Router />
			</Container>
		</>
	);
};
export default Layout;
