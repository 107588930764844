const defaultLocations: AppLocation[] = [
	{
		parentValue: null,
		value: "f32343fac7d74f5991e5b90926bac9e5",
		label: "House 1",
		levelLabel: "House",
	},
	{
		parentValue: "f32343fac7d74f5991e5b90926bac9e5",
		value: "b654b63b4be34bfc860d26e9b11c35d6",
		label: "Bay 1",
		levelLabel: "Bay",
	},
	{
		parentValue: "b654b63b4be34bfc860d26e9b11c35d6",
		value: "0abe27ea474b419ea6e09f4ee1430c07",
		label: "North",
		levelLabel: "Section",
	},
	{
		parentValue: "b654b63b4be34bfc860d26e9b11c35d6",
		value: "666383992e584ce9a9a1f883ce2672ad",
		label: "South",
		levelLabel: "Section",
	},
	{
		parentValue: "b654b63b4be34bfc860d26e9b11c35d6",
		value: "528db00ed46b4a81896d0031b48b0a58",
		label: "-",
		levelLabel: "Section",
	},
	{
		parentValue: "f32343fac7d74f5991e5b90926bac9e5",
		value: "730f98930452457da2b48943f9ea12f6",
		label: "Bay 2",
		levelLabel: "Bay",
	},
	{
		parentValue: "730f98930452457da2b48943f9ea12f6",
		value: "245a7fd950cb45cfa907f6e9fd6f37bf",
		label: "North",
		levelLabel: "Section",
	},
	{
		parentValue: "730f98930452457da2b48943f9ea12f6",
		value: "3c15dff97b4f4026aff809dda602ffec",
		label: "South",
		levelLabel: "Section",
	},
	{
		parentValue: "730f98930452457da2b48943f9ea12f6",
		value: "543bdc55cedf4c019ba078c2e8930b6f",
		label: "-",
		levelLabel: "Section",
	},
	{
		parentValue: "f32343fac7d74f5991e5b90926bac9e5",
		value: "bcb0bb30f85647b78de68801f80eaab7",
		label: "Bay 3",
		levelLabel: "Bay",
	},
	{
		parentValue: "bcb0bb30f85647b78de68801f80eaab7",
		value: "7a6b10bb8dd14ff3a3b61971d6909ec0",
		label: "North",
		levelLabel: "Section",
	},
	{
		parentValue: "bcb0bb30f85647b78de68801f80eaab7",
		value: "ad8639cd97344f789931d9de8df2adff",
		label: "South",
		levelLabel: "Section",
	},
	{
		parentValue: "bcb0bb30f85647b78de68801f80eaab7",
		value: "a836929fd04543df948c1f06c63ac0d8",
		label: "-",
		levelLabel: "Section",
	},
	{
		parentValue: "f32343fac7d74f5991e5b90926bac9e5",
		value: "95789ee01b354ddd83346344ec22b9c8",
		label: "Bay 4",
		levelLabel: "Bay",
	},
	{
		parentValue: "95789ee01b354ddd83346344ec22b9c8",
		value: "ab282fb852424eb986bf6c49ed6dd3ff",
		label: "North",
		levelLabel: "Section",
	},
	{
		parentValue: "95789ee01b354ddd83346344ec22b9c8",
		value: "6ae27a5efa9a4b8bbaad6ef03c7ed446",
		label: "South",
		levelLabel: "Section",
	},
	{
		parentValue: "95789ee01b354ddd83346344ec22b9c8",
		value: "3b31f98daac746dabf95503e1f7a9d95",
		label: "-",
		levelLabel: "Section",
	},
	{
		parentValue: "f32343fac7d74f5991e5b90926bac9e5",
		value: "d797818ebbf74e748361b6dd121f0625",
		label: "-",
		levelLabel: "Bay",
	},
	{
		parentValue: "d797818ebbf74e748361b6dd121f0625",
		value: "50c9c10cf2434f909be29a639e78dd24",
		label: "-",
		levelLabel: "Section",
	},
	{
		parentValue: null,
		value: "3fd5f43b71844a5bb3f08170725ade44",
		label: "House 2",
		levelLabel: "House",
	},
	{
		parentValue: "3fd5f43b71844a5bb3f08170725ade44",
		value: "5ceb11e03890417b8b86947f8a855ffe",
		label: "Bay 1",
		levelLabel: "Bay",
	},
	{
		parentValue: "5ceb11e03890417b8b86947f8a855ffe",
		value: "be130b84deb349ee9d9366a7316e2236",
		label: "North",
		levelLabel: "Section",
	},
	{
		parentValue: "5ceb11e03890417b8b86947f8a855ffe",
		value: "2100a281400b4e6fbabc78faf22ef3e4",
		label: "South",
		levelLabel: "Section",
	},
	{
		parentValue: "5ceb11e03890417b8b86947f8a855ffe",
		value: "ede9e2f2daba496cadb816d22b674071",
		label: "-",
		levelLabel: "Section",
	},
	{
		parentValue: "3fd5f43b71844a5bb3f08170725ade44",
		value: "9d9f247d41744ade8496f6c6c9db227e",
		label: "Bay 2",
		levelLabel: "Bay",
	},
	{
		parentValue: "9d9f247d41744ade8496f6c6c9db227e",
		value: "0799d84464684042b99ec4fd3cca9a2b",
		label: "North",
		levelLabel: "Section",
	},
	{
		parentValue: "9d9f247d41744ade8496f6c6c9db227e",
		value: "aaa5bf10ac4b4d59b0c7b7c3024257b2",
		label: "South",
		levelLabel: "Section",
	},
	{
		parentValue: "9d9f247d41744ade8496f6c6c9db227e",
		value: "09dc1144f1fb4406b3e7b4f058b1b310",
		label: "-",
		levelLabel: "Section",
	},
	{
		parentValue: "3fd5f43b71844a5bb3f08170725ade44",
		value: "1417bd5492bc45e1ba9fe362b1ae6899",
		label: "Bay 3",
		levelLabel: "Bay",
	},
	{
		parentValue: "1417bd5492bc45e1ba9fe362b1ae6899",
		value: "7704661bdc494675a0832bb749bf613b",
		label: "North",
		levelLabel: "Section",
	},
	{
		parentValue: "1417bd5492bc45e1ba9fe362b1ae6899",
		value: "57e2722f1e0a4eb7b4844d347731b181",
		label: "South",
		levelLabel: "Section",
	},
	{
		parentValue: "1417bd5492bc45e1ba9fe362b1ae6899",
		value: "c87d382146a84409bd7c210e740c7584",
		label: "-",
		levelLabel: "Section",
	},
	{
		parentValue: "3fd5f43b71844a5bb3f08170725ade44",
		value: "c3ee06616ea548bf99b088419cd569c9",
		label: "Bay 4",
		levelLabel: "Bay",
	},
	{
		parentValue: "c3ee06616ea548bf99b088419cd569c9",
		value: "646293254f2b430097365164d54265cc",
		label: "North",
		levelLabel: "Section",
	},
	{
		parentValue: "c3ee06616ea548bf99b088419cd569c9",
		value: "376da7dc5f3e4da28a99e1d124c7541a",
		label: "South",
		levelLabel: "Section",
	},
	{
		parentValue: "c3ee06616ea548bf99b088419cd569c9",
		value: "39b8fd52101d4599bb5169d6ccf0fb14",
		label: "-",
		levelLabel: "Section",
	},
	{
		parentValue: "3fd5f43b71844a5bb3f08170725ade44",
		value: "0dd4904fb5184561ad852d6e14dfad75",
		label: "-",
		levelLabel: "Bay",
	},
	{
		parentValue: "0dd4904fb5184561ad852d6e14dfad75",
		value: "dfb9330882234d65aaa4fa9ea8dd2797",
		label: "-",
		levelLabel: "Section",
	},
	{
		parentValue: null,
		value: "a3791ae9fc5648a0a2eb85e62300ce75",
		label: "House 4",
		levelLabel: "House",
	},
	{
		parentValue: "a3791ae9fc5648a0a2eb85e62300ce75",
		value: "20c0500c31f844d4a97f03dccba92b59",
		label: "Bay 1",
		levelLabel: "Bay",
	},
	{
		parentValue: "20c0500c31f844d4a97f03dccba92b59",
		value: "ee332edb54fc409eb637381d7461ed1a",
		label: "North",
		levelLabel: "Section",
	},
	{
		parentValue: "20c0500c31f844d4a97f03dccba92b59",
		value: "94ce2ea7dcd14085af6a010e1e6f1c6b",
		label: "South",
		levelLabel: "Section",
	},
	{
		parentValue: "20c0500c31f844d4a97f03dccba92b59",
		value: "319aec7ba01a4553b7d4b787a9092009",
		label: "-",
		levelLabel: "Section",
	},
	{
		parentValue: "a3791ae9fc5648a0a2eb85e62300ce75",
		value: "733c7dae3cef4957a46ae5a7d2076c72",
		label: "Bay 2",
		levelLabel: "Bay",
	},
	{
		parentValue: "733c7dae3cef4957a46ae5a7d2076c72",
		value: "598b54486507438dbea1bc8674360bfa",
		label: "North",
		levelLabel: "Section",
	},
	{
		parentValue: "733c7dae3cef4957a46ae5a7d2076c72",
		value: "532c5111803145d1be17f6a2d3a868f2",
		label: "South",
		levelLabel: "Section",
	},
	{
		parentValue: "733c7dae3cef4957a46ae5a7d2076c72",
		value: "379f7717a9fa461484b3efe1cef414d0",
		label: "-",
		levelLabel: "Section",
	},
	{
		parentValue: "a3791ae9fc5648a0a2eb85e62300ce75",
		value: "4bacb9d5c4c44215934bc79cfa64be60",
		label: "Bay 3",
		levelLabel: "Bay",
	},
	{
		parentValue: "4bacb9d5c4c44215934bc79cfa64be60",
		value: "734991621b0a475198a7f992fb3a80b6",
		label: "North",
		levelLabel: "Section",
	},
	{
		parentValue: "4bacb9d5c4c44215934bc79cfa64be60",
		value: "eebb81aa90ad44d1999a814184385699",
		label: "South",
		levelLabel: "Section",
	},
	{
		parentValue: "4bacb9d5c4c44215934bc79cfa64be60",
		value: "21997e2076a14429a666204937a5f335",
		label: "-",
		levelLabel: "Section",
	},
	{
		parentValue: "a3791ae9fc5648a0a2eb85e62300ce75",
		value: "c1a624ae6b2b4656acedaff0194a6db5",
		label: "Bay 4",
		levelLabel: "Bay",
	},
	{
		parentValue: "c1a624ae6b2b4656acedaff0194a6db5",
		value: "bef49f51507b4708a0fb5479158621c6",
		label: "North",
		levelLabel: "Section",
	},
	{
		parentValue: "c1a624ae6b2b4656acedaff0194a6db5",
		value: "40573e12fc034bf2905da29ff330788a",
		label: "South",
		levelLabel: "Section",
	},
	{
		parentValue: "c1a624ae6b2b4656acedaff0194a6db5",
		value: "0fb517e0fbed4cf7bdb2ddc249662d30",
		label: "-",
		levelLabel: "Section",
	},
	{
		parentValue: "a3791ae9fc5648a0a2eb85e62300ce75",
		value: "581602312f5a4e0a9e183591d09f7836",
		label: "-",
		levelLabel: "Bay",
	},
	{
		parentValue: "581602312f5a4e0a9e183591d09f7836",
		value: "e4d5219a7ba0459d9e33a744a6668786",
		label: "-",
		levelLabel: "Section",
	},
	{
		parentValue: null,
		value: "a10c1c7f06144ba3a3ce6bda06b0831d",
		label: "Outside",
		levelLabel: "House",
	},
	{
		parentValue: "a10c1c7f06144ba3a3ce6bda06b0831d",
		value: "95861ababa154d2a9ab25acc7d657829",
		label: "Bay 1",
		levelLabel: "Bat",
	},
	{
		parentValue: "95861ababa154d2a9ab25acc7d657829",
		value: "e52179059f614902b7767c1d6f4e68f0",
		label: "North",
		levelLabel: "Section",
	},
	{
		parentValue: "95861ababa154d2a9ab25acc7d657829",
		value: "17f0020d05ad456ab14606b23aa9d27a",
		label: "South",
		levelLabel: "Section",
	},
	{
		parentValue: "95861ababa154d2a9ab25acc7d657829",
		value: "ff4a71a0e4834824b0b074187812f128",
		label: "-",
		levelLabel: "Section",
	},
	{
		parentValue: "a10c1c7f06144ba3a3ce6bda06b0831d",
		value: "d12c4e645a8e4709bebd8f93099af627",
		label: "Bay 2",
		levelLabel: "Bay",
	},
	{
		parentValue: "d12c4e645a8e4709bebd8f93099af627",
		value: "2543cd358e5f42e687af7e7bb6aa1114",
		label: "North",
		levelLabel: "Section",
	},
	{
		parentValue: "d12c4e645a8e4709bebd8f93099af627",
		value: "01e1327fb2b94e3e93e3fdd248ee7676",
		label: "South",
		levelLabel: "Section",
	},
	{
		parentValue: "d12c4e645a8e4709bebd8f93099af627",
		value: "20438d7a4b3e4717a261460dd78065bf",
		label: "-",
		levelLabel: "Section",
	},
	{
		parentValue: "a10c1c7f06144ba3a3ce6bda06b0831d",
		value: "e988308bae2846ac87f16c0f1c8273d1",
		label: "Bay 3",
		levelLabel: "Bay",
	},
	{
		parentValue: "e988308bae2846ac87f16c0f1c8273d1",
		value: "8fbfe55e80434c08afde9a61e1121422",
		label: "North",
		levelLabel: "Section",
	},
	{
		parentValue: "e988308bae2846ac87f16c0f1c8273d1",
		value: "368ee415aedd4add8919a63d4638ff04",
		label: "South",
		levelLabel: "Section",
	},
	{
		parentValue: "e988308bae2846ac87f16c0f1c8273d1",
		value: "17bea499a9e941f0892c0bc354f1b700",
		label: "-",
		levelLabel: "Section",
	},
	{
		parentValue: "a10c1c7f06144ba3a3ce6bda06b0831d",
		value: "806ceeba3f214f178651a6cf8667f6d5",
		label: "Bay 4",
		levelLabel: "Bay",
	},
	{
		parentValue: "806ceeba3f214f178651a6cf8667f6d5",
		value: "c1666261368f4d0093d37b4c2874308d",
		label: "North",
		levelLabel: "Section",
	},
	{
		parentValue: "806ceeba3f214f178651a6cf8667f6d5",
		value: "878551a04b7c4a0ab7391862b38d32ff",
		label: "South",
		levelLabel: "Section",
	},
	{
		parentValue: "806ceeba3f214f178651a6cf8667f6d5",
		value: "b86c806c34124efbbc2a8da8e2956eb3",
		label: "-",
		levelLabel: "Section",
	},
	{
		parentValue: "a10c1c7f06144ba3a3ce6bda06b0831d",
		value: "6a8975e8b6b7412aa5a37a0003d350ef",
		label: "-",
		levelLabel: "Bay",
	},
	{
		parentValue: "6a8975e8b6b7412aa5a37a0003d350ef",
		value: "0d3c80993eea417aa05dbbed5aeeb45d",
		label: "-",
		levelLabel: "Section",
	},
];

export default defaultLocations;
