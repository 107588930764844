import { useContext, useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../auth/AuthProvider";

import db from "../db";

//graph
import { GraphQLContext } from "../providers/GraphqlProvider";
import { ItemSummaryDocument, ItemsQuery, ItemsQueryVariables, getSdk } from "../gql/graphql";

//ui
import ModalDialog, { DialogActionButtons } from "../components/ModalDialog";

import { TranslationContext } from "../providers/TranslationProvider";
import { produce } from "immer";
import { Box, Typography, Alert, Button } from "@mui/material";

const Sync = () => {
	const authContext = useContext(AuthContext);
	const { getTranslation } = useContext(TranslationContext);
	const navigate = useNavigate();

	const [syncingStatus, setSyncStatus] = useState<{ [key: string]: SyncStatusData }>({});
	const [errors, setErrors] = useState<string[]>([]);
	const [showErrors, setShowErrors] = useState(false);
	const [actions, setActions] = useState<DialogActionButtons[] | null>(null);

	const syncStarted = useRef(false);

	const handleSyncDone = (val: boolean) => {
		if (val) {
			navigate("/");
		} else {
			setActions([
				{
					children: getTranslation("action-cancel"),
					props: {
						color: "info",
						onClick: () => {
							handleSyncDone(true);
						},
					},
				},
			]);
		}
	};

	const { graphClient } = useContext(GraphQLContext);
	const sdk = getSdk(graphClient);

	let newItems: ItemSummaryDocument[] = [];

	const updateItems = async (data: ItemsQuery, variables: ItemsQueryVariables) => {
		if (!data.items?.nodes) {
			console.log("No Data...");
			return;
		}
		newItems = [...newItems, ...data.items.nodes];
		let d: SyncStatus = {};
		d["Items"] = { currentCount: newItems.length, totalCount: data.items.totalCount };
		setSyncStatus(
			produce((draft) => {
				return { ...draft, ...d };
			})
		);
		console.log("hasnextpage", data.items.pageInfo.hasNextPage);
		if (data.items.pageInfo.hasNextPage) {
			variables = { ...variables, after: data.items.pageInfo.endCursor };
			let res = await sdk.items(variables);
			updateItems(res, variables);
		} else {
			try {
				await db.items.clear();
				await db.items.bulkAdd(newItems);
				handleSyncDone(true);
			} catch (e) {
				setErrors(
					produce((draft) => {
						return [...draft, String(e)];
					})
				);
				console.log(e);
				handleSyncDone(false);
			}
		}
	};

	useEffect(() => {
		console.log(syncStarted.current);
		const syncItems = async () => {
			try {
				const variables = { tenantId: authContext.company?.abbreviation || "NOTFOUND", first: 1000 };
				const itemsRes = await sdk.items(variables);
				console.log("itemsRes", itemsRes);
				updateItems(itemsRes, variables);
			} catch (e) {
				setErrors(
					produce((draft) => {
						return [...draft, String(e)];
					})
				);
				console.log(e);
				handleSyncDone(false);
			}
		};
		if (!syncStarted.current) {
			syncStarted.current = true;
			syncItems();
		}
	}, []);

	return (
		<ModalDialog
			open={true}
			close={() => {
				return false;
			}}
			title={getTranslation("info-syncing-data")}
			actions={actions}
		>
			{Object.keys(syncingStatus).length > 0 || errors.length > 0 ? (
				<>
					{errors.length > 0 ? (
						<>
							<Alert
								severity="error"
								action={
									<Button variant="contained" color="inherit" size="small" onClick={() => setShowErrors(!showErrors)}>
										{showErrors ? "Hide" : "Show"}
									</Button>
								}
							>
								{errors.length} {getTranslation("error-error-syncing-data")}
							</Alert>
							{showErrors ? (
								<Box>
									<ol>
										{errors.map((err, _i) => (
											<li>{`${String(err)}`}</li>
										))}
									</ol>
								</Box>
							) : null}
						</>
					) : null}
					{Object.keys(syncingStatus).map((v, i) => {
						return (
							<Box key={i}>
								<Typography variant="inherit" color="textSecondary">
									{v}
								</Typography>
								<Typography variant="subtitle1" color="textPrimary" gutterBottom>
									{syncingStatus[v]?.currentCount} of {syncingStatus[v]?.totalCount}{" "}
								</Typography>
							</Box>
						);
					})}
				</>
			) : (
				<Alert severity="info">
					{getTranslation("info-sync-starting")}
					...
				</Alert>
			)}
		</ModalDialog>
	);
};

export default Sync;
