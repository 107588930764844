import { useContext } from "react";
import { Grid, Typography } from "@mui/material";
import { TranslationContext } from "../providers/TranslationProvider";

import locationTreeToString from "../helpers/locationTreeToString";

interface LotDetailProps {
	count: AppItemCount;
}

const LotDetail = ({ count }: LotDetailProps) => {
	const { getTranslation } = useContext(TranslationContext);

	return (
		<Grid container spacing={0} alignItems="stretch" direction="row">
			<Grid item xs={12}>
				<Typography variant="inherit" component="span" color="textSecondary">
					{getTranslation("general-loc")}:
				</Typography>{" "}
				{locationTreeToString(count.location || [])}
			</Grid>
			<Grid item xs={12}>
				{`${count.itemDetail.name}`}
			</Grid>
			<Grid item xs={8}>
				<Typography variant="inherit" component="span" color="textSecondary">
					{getTranslation("term-upc")} #:
				</Typography>{" "}
				{`${count.itemDetail.upc}`}
			</Grid>
			<Grid item xs={4}>
				<Typography variant="inherit" component="span" color="textSecondary">
					{getTranslation("general-qty")}:
				</Typography>{" "}
				<b>{`${count.itemCount.quantity}`}</b>
			</Grid>
			<Grid item xs={4}>
				<Typography variant="inherit" component="span" color="textSecondary">
					{getTranslation("general-grade")}:
				</Typography>{" "}
				<b>{`${count.itemCount.grade}`}</b>
			</Grid>
			{count.itemCount.comment && (
				<Grid item xs={12}>
					<Typography variant="inherit" component="span" color="textSecondary">
						{getTranslation("general-comment")}:
					</Typography>{" "}
					{`${count.itemCount.comment}`}
				</Grid>
			)}
		</Grid>
	);
};

export default LotDetail;
