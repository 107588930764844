import { Route, Routes } from "react-router-dom";

import RequireAuth from "./auth/RequireAuth";

//pages
import Count from "./pages/Count";
import Post from "./pages/Post";
import Done from "./pages/Done";
import Sync from "./pages/Sync";
import ErrorLog from "./pages/ErrorLog";
import Forbidden from "./pages/Forbidden";
import Settings from "./pages/Settings";
import OAuthRedirect from "./pages/OAuthRedirect";

const Router = () => {
	return (
		<Routes>
			<Route path="/oauth-redirect" element={<OAuthRedirect />} />
			<Route path="/Forbidden" element={<Forbidden />} />
			<Route path="/errorLog" element={<ErrorLog />} />
			<Route
				path="/settings"
				element={
					<RequireAuth>
						<Settings />
					</RequireAuth>
				}
			/>
			<Route
				path="/sync"
				element={
					<RequireAuth>
						<Sync />
					</RequireAuth>
				}
			/>
			<Route
				path="/post"
				element={
					<RequireAuth>
						<Post />
					</RequireAuth>
				}
			/>
			<Route
				path="/done"
				element={
					<RequireAuth>
						<Done />
					</RequireAuth>
				}
			/>
			<Route
				path="/"
				element={
					<RequireAuth>
						<Count />
					</RequireAuth>
				}
			/>

			<Route path="*" element={<p>Path not resolved</p>} />
		</Routes>
	);
};

export default Router;
