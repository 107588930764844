import { ThemeProvider, StyledEngineProvider, CssBaseline } from "@mui/material";
import { themeCreator } from "../themes/pageThemes";
import { createContext, useState } from "react";

export const ThemeContext = createContext({
	themeName: "mainTheme",
	themeType: "light",
	themeSize: "14",
	setTheme: (_themeName: string) => {},
	setThemeType: (_type: string) => {},
	setThemeSize: (_size: string) => {},
});

interface AppThemeProps {
	children: JSX.Element;
}

const AppThemeProvider = (props: AppThemeProps) => {
	// State to hold the selected theme name
	const { children } = props;

	let userpref = localStorage.getItem("themeType") === "dark" ? "dark" : "light";
	let userprefSize = localStorage.getItem("themeSize") || "14";
	const [themeName, setThemeName] = useState("main");
	const [themeType, setThemeTypePref] = useState(userpref);
	const [themeSize, setThemeSizePref] = useState(userprefSize);

	const theme = themeCreator(themeName, themeType, themeSize);

	function setThemeType(type: string) {
		localStorage.setItem("themeType", type);
		setThemeTypePref(type);
	}

	function setThemeSize(size: string) {
		localStorage.setItem("themeSize", size);
		setThemeSizePref(size);
	}

	const contextValue = {
		themeName: themeName,
		setTheme: setThemeName,
		themeType: themeType,
		setThemeType: setThemeType,
		themeSize: themeSize,
		setThemeSize: setThemeSize,
	};

	return (
		<StyledEngineProvider injectFirst>
			<ThemeContext.Provider value={contextValue}>
				<ThemeProvider theme={theme}>
					<CssBaseline />
					{children}
				</ThemeProvider>
			</ThemeContext.Provider>
		</StyledEngineProvider>
	);
};

export default AppThemeProvider;
