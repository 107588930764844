//material-ui
import { Grid, GridProps } from "@mui/material";

interface MaterialBoxProps {
	error?: boolean;
}

const CountBox: React.FC<MaterialBoxProps & GridProps & React.ComponentProps<typeof Grid>> = ({ error, children, ...props }) => {
	return (
		<Grid
			container
			spacing={0}
			alignItems="stretch"
			direction="row"
			sx={{
				backgroundColor: (theme) => theme.palette.background.paper,
				borderWidth: error ? "2px" : "0 0 1px 0",
				borderStyle: "solid",
				borderColor: (theme) => (error ? theme.palette.error.main : theme.palette.divider),
				height: "100%",
				padding: 1,
			}}
			{...props}
		>
			{children}
		</Grid>
	);
};

export default CountBox;
