import { useState, useEffect, useRef, useContext } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
//material-ui
import { Alert, Autocomplete, Button, TextField, IconButton, ToggleButton, ToggleButtonGroup, Grid } from "@mui/material";

import SpacedGridContainer from "../components/SpacedGridContainer";
import SpacedGridItem from "../components/SpacedGridItem";

import CalculatorIcon from "@mui/icons-material/Calculate";

//dexie
import db from "../db";

//providers
import { useSnacks } from "../providers/SnackProvider";
import { AuthContext } from "../auth/AuthProvider";

//components
import Calc from "../components/calc";
import LocationMenu from "../components/LocationMenu";

//local
import defaultLocations from "../helpers/defaultLocations";
import { CountDataInput, ItemSummaryDocument } from "../gql/graphql";

const Availability = () => {
	const defaultInput: CountDataInput = {
		locationId: "",
		quantity: 0,
		comment: "",
		grade: 2,
		itemId: "",
		recordedAt: "",
		recordedBy: "",
	};
	const authContext = useContext(AuthContext);

	let { state } = useLocation();
	const navigate = useNavigate();

	const [locations, _setLocations] = useState<AppLocation[]>(defaultLocations);
	const [items, setItems] = useState<ItemSummaryDocument[]>([]);
	const [dataLoading, setDataLoading] = useState(true);
	const [calcOpen, setCalcOpen] = useState(false);

	const [acKey, setAcKey] = useState(Date.now());
	const [input, setInput] = useState<CountDataInput>(defaultInput);

	const [openSnack] = useSnacks();
	const [acVal, setAcVal] = useState<ItemSummaryDocument | null>(null);
	const [acOpen, setAcOpen] = useState(false);

	const [itemCountId, setItemCountId] = useState(Number(state?.itemCountId) || 0);

	const acInput = useRef<HTMLInputElement>(null);
	const qtyInput = useRef<HTMLInputElement>(null);

	const handleInputChange = (val: Partial<CountDataInput>) => {
		setInput((prevState: any) => ({
			...prevState,
			...val,
		}));
	};

	const setQuantity = (val: number | string) => {
		handleInputChange({ quantity: Number(val) });
	};

	const setLocation = (locationId: string) => {
		handleInputChange({ locationId: locationId });
	};

	const handleGrade = (_event: React.MouseEvent<HTMLElement>, newGrade: number | null) => {
		if (!newGrade) return;
		handleInputChange({ grade: Number(newGrade) });
	};

	const handleSave = async () => {
		if (input.itemId && input.quantity && input.locationId) {
			let count: CountDataInput = { ...input };
			count.recordedAt = new Date().toISOString().slice(0, 19);
			count.recordedBy = authContext.currentUser?.email || "unknown";
			//count.tenantId = authContext.company?.abbreviation || "unknown";
			//count.images = images.map((image: string) => ({ Image: image }));
			let countDetail = await db.items.get(count.itemId);
			if (itemCountId) {
				db.itemCounts
					.update(itemCountId, { itemId: count.itemId, status: "", itemCount: count, itemDetail: countDetail })
					.then(() => {
						setInput((prevState) => ({
							...prevState,
							...defaultInput,
							locationId: prevState.locationId,
						}));
						setAcVal(null);
						setAcKey(Date.now());
						setItemCountId(0);
						navigate("/post");
						openSnack("Count Updated", "success");
					})
					.catch(() => {
						openSnack("Count Not Updated!", "error");
					});
			} else {
				db.itemCounts
					.add({
						itemId: count.itemId,
						status: "",
						itemCount: count,
						itemDetail: countDetail,
						location: JSON.parse(localStorage.getItem("locationSelected") || ""),
					})
					.then(() => {
						setInput((prevState) => ({
							...prevState,
							...defaultInput,
							locationId: prevState.locationId,
						}));
						setAcVal(null);
						setAcKey(Date.now());
						setItemCountId(0);
						openSnack("Count Saved", "success");
						if (null !== acInput?.current) {
							acInput.current.focus();
						}
					})
					.catch((e) => {
						openSnack("Count Not Saved!", "error");
						console.log(e);
					});
			}
		} else {
			openSnack("Item, Quantity and Location are Required!", "warning");
		}
	};

	const updateFromData = async () => {
		//await db.locations.toArray((data) => {
		//	setLocations(data);
		//});
		await db.items.toArray((data) => {
			setItems(data);
		});
		setDataLoading(false);
	};

	const acFilter = (options: ItemSummaryDocument[], state: any) => {
		const result = options.filter((option: any) => `${option.name}, ${option.upc}, ${option.sku}`.toLowerCase().indexOf(state.inputValue.toLowerCase()) > -1);
		//was auto select if 1 result and UPC like (state.inputValue.length === 12)
		//auto select if only one result
		if (result.length === 1) {
			setAcVal(result[0]);
			handleInputChange({
				itemId: result[0]?.itemId,
			});
			setAcOpen(false);
			setTimeout(() => {
				if (null !== qtyInput?.current) {
					qtyInput.current.focus();
				}
			}, 100);
		}
		return result;
	};

	useEffect(() => {
		updateFromData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (calcOpen) {
			if (null !== acInput?.current) {
				window.scrollTo(0, acInput.current.offsetTop);
			}
		}
	}, [calcOpen]);

	//Load Info from Queue for Editing
	useEffect(() => {
		const setEditItem = async () => {
			db.itemCounts.get(itemCountId).then((ic) => {
				if (ic) {
					setInput((prevState: any) => ({
						...prevState,
						...ic.itemCount,
					}));
					setAcVal(ic.itemDetail);
				}
			});
		};

		if (!dataLoading && itemCountId) {
			setEditItem();
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dataLoading, itemCountId]);

	if (dataLoading) {
		return null;
	}

	return (
		<>
			{itemCountId ? (
				<Alert severity="warning" style={{ marginBottom: "1em" }}>
					Editing existing count
				</Alert>
			) : null}
			{dataLoading ? (
				<Alert severity="info">Loading..</Alert>
			) : (
				<SpacedGridContainer>
					<LocationMenu items={locations} selectItem={state?.locationId || ""} onLeafItemSelected={setLocation} />
					{!input.locationId ? (
						<SpacedGridItem maxCols={1} smMargin={true}>
							<Alert severity="info">Select a Location</Alert>
						</SpacedGridItem>
					) : (
						<>
							<SpacedGridItem maxCols={2} smMargin={true}>
								<Autocomplete
									value={acVal}
									open={acOpen}
									onOpen={() => setAcOpen(true)}
									onClose={() => setAcOpen(false)}
									//onSelect={()=> setAcOpen(false)}
									key={acKey}
									onChange={(_e, v) => {
										setAcVal(v);
										handleInputChange({
											itemId: v?.itemId,
										});
									}}
									filterOptions={acFilter}
									isOptionEqualToValue={(option, value) => option.itemId === value.itemId}
									id="upc-input"
									options={items}
									getOptionLabel={(option) => (option.itemId ? `${option.name}, ${option.upc}, ${option.sku}` : "")}
									style={{ width: "100%" }}
									renderInput={(params) => <TextField {...params} label="UPC" variant="outlined" autoFocus={true} ref={acInput} />}
								/>
							</SpacedGridItem>
							<SpacedGridItem maxCols={2} smMargin={true}>
								<TextField
									type="numeric"
									variant="standard"
									fullWidth={true}
									label="Quantity"
									inputRef={qtyInput}
									value={input.quantity}
									onChange={(e) => {
										let crtval = e.target.value.replace(/[^0-9.]/g, "");
										if (crtval === "") {
											setQuantity("");
										} else {
											setQuantity(Number(crtval));
										}
									}}
									InputProps={{
										endAdornment: (
											<IconButton aria-label="open calc" onClick={() => setCalcOpen((prevState) => !prevState)} sx={{ padding: 0, marginRight: 1 }}>
												<CalculatorIcon fontSize="large" color="primary" />
											</IconButton>
										),
									}}
								></TextField>
							</SpacedGridItem>
							{calcOpen ? (
								<SpacedGridItem maxCols={2} smMargin={true}>
									<Calc setQuantity={setQuantity} setCalcOpen={setCalcOpen} />
								</SpacedGridItem>
							) : null}
							<SpacedGridItem maxCols={2} smMargin={true}>
								<Grid container spacing={2}>
									<Grid item>
										<Button variant="text">Grade</Button>{" "}
									</Grid>
									<Grid item xs>
										<ToggleButtonGroup size="small" color="primary" value={input.grade} exclusive onChange={handleGrade} fullWidth={true} aria-label="Grade">
											<ToggleButton value={1} aria-label="1">
												1
											</ToggleButton>
											<ToggleButton value={2} aria-label="2">
												2
											</ToggleButton>
											<ToggleButton value={3} aria-label="3">
												3
											</ToggleButton>
										</ToggleButtonGroup>
									</Grid>
								</Grid>
							</SpacedGridItem>

							<SpacedGridItem maxCols={2} smMargin={true}>
								<TextField
									variant="standard"
									fullWidth={true}
									label="Comment"
									type="text"
									name="comment"
									value={input.comment}
									onChange={(e) => handleInputChange({ [e.currentTarget.name]: e.currentTarget.value })}
								></TextField>
							</SpacedGridItem>

							<SpacedGridItem maxCols={2} smMargin={true}>
								<Button fullWidth={true} onClick={() => handleSave()} variant="contained" color="primary">
									Save
								</Button>
							</SpacedGridItem>
							{itemCountId ? (
								<SpacedGridItem maxCols={2} smMargin={true}>
									<Link to="/post">
										<Button fullWidth={true} variant="contained" color="secondary">
											Cancel
										</Button>
									</Link>
								</SpacedGridItem>
							) : null}
						</>
					)}
				</SpacedGridContainer>
			)}
		</>
	);
};

export default Availability;
