import { useState } from "react";

//material-ui
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";

//helpers
import { Parser } from "expr-eval";

//providers
import { useSnacks } from "../../providers/SnackProvider";

//local
import InputButtons from "./InputButtons";

interface CalcProps {
	setQuantity: (value: number) => void;
	setCalcOpen: (value: boolean) => void;
	parentheses?: boolean;
}

const Calc = (props: CalcProps) => {
	const { setQuantity, setCalcOpen, parentheses } = props;

	const [exp, setExp] = useState("");

	const [openSnack] = useSnacks();

	const handleInput = (_e: any, text: any) => {
		// alert('current value = ' + this.state.value + ', input = ' + text);
		if (text === "del") {
			setExp((exp) => {
				return exp.slice(0, -1);
			});
		} else {
			setExp((exp) => {
				return exp + String(text);
			});
		}
	};

	const handleCalcRequest = () => {
		try {
			const value = Parser.evaluate(exp.replace("%", "/100"));
			//("exp", exp, "ast", ast, "value", value, typeof(value));
			if (value === undefined || value === null || isNaN(value)) {
				openSnack("Invalid Result", "error");
				return;
			}

			if (value > 999999999999999) {
				//15 digits
				openSnack("Value too large", "error");
				return;
			}

			if (value < 0) {
				//15 digits
				openSnack("Value cannot be negative", "error");
				return;
			}

			if (!isNaN(value)) {
				setQuantity(value);
			}
			//console.log(value);
			setExp("");
			setCalcOpen(false);
		} catch {
			openSnack("Count not calculate expression", "error");
		}
	};

	const handleInputFieldChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		// alert(event.target.value);
		setExp(e.target.value);
	};

	const handleInputFieldKeyPress = (e: React.KeyboardEvent<HTMLDivElement>) => {
		if (e.key === "Enter") {
			handleCalcRequest();
		}
	};

	return (
		<>
			<Grid container spacing={2} alignItems="stretch" direction="row">
				<Grid item xs={12}>
					<TextField
						value={exp}
						style={{ fontSize: "25px" }}
						fullWidth={true}
						onChange={handleInputFieldChange}
						variant="standard"
						onKeyDown={handleInputFieldKeyPress}
					/>
				</Grid>
				<InputButtons onInput={handleInput} onCalcRequest={handleCalcRequest} parentheses={parentheses || false} />
			</Grid>
			<div id="calc-right-column"></div>
		</>
	);
};

export default Calc;
