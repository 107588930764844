import { AuthContext } from "../auth";
import { useContext } from "react";
import { LinearProgress } from "@mui/material";
import Forbidden from "../pages/Forbidden";

interface RequireAuthProps {
	/**
	 * A callback to authorize the user *in addition* to authentication; ie if your route requires no
	 * additional authorization, simply provide a callback that returns `true`. To mount a route that
	 * requires no authentication at all, assign to `null`.
	 * @default () => true
	 */
	authorize?: (user?: Maybe<SFUser>, company?: Maybe<SFCompany>) => boolean;

	/** Action to take if the current user is not authorized
	 * @default 'skip'
	 */
	unauthorizedBehavior?: AuthGateBehavior;

	/** Content to render if current user is authorized */
	children: React.ReactNode;
}

/**
 * Ensures user is authorized before rendering children. Additional conditions can be
 * enforced via the `authorize` callback prop, which accepts the userDoc as a parameter.
 */
function RequireAuth({ authorize = () => true, unauthorizedBehavior = "skip", children }: RequireAuthProps) {
	const { loggedIn, sessionChecked, currentUser, company, redirectToLogin } = useContext(AuthContext);
	/*SCOTT
	useEffect(() => {
		loadUserSession();
	}, [loadUserSession]);
	*/

	// Just provides easy toggling of auth during dev
	const shouldAuthorize = true;

	if (!shouldAuthorize) {
		return <>{children}</>;
	}

	if (!sessionChecked) {
		// Auth is required and user auth hasn't loaded yet - display Spinner
		return <LinearProgress />;
	}

	if (loggedIn && authorize(currentUser, company)) {
		return <>{children}</>;
	} else {
		switch (unauthorizedBehavior) {
			case "skip":
				return <></>;
			case "forbid":
				return <Forbidden />;
			case "redirect":
				redirectToLogin();
				return <></>;
		}
	}
}

export default RequireAuth;
